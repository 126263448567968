<app-nav></app-nav>
<div style="background-color: #fff;">
    <div class="corpus_top">
        <div class="ct_container d-flex container">
            <div class="ctc_content">
                <div class="ctcc_title">
                    {{"资源文本化服务" |translate}}
                </div>
                <div class="ctcc_title_en" *ngIf="language !== 'en'">
                    Resource textualization service
                </div>
                <div class="ctcc_title_sub" [innerHTML]="'tuIntroduction' | translate">
                    提供各类文字的扫描服务，以及蒙古文、中文、英文等内容的文本化服务。
                </div>
            </div>
            <div class="ctc_img">
                <img loading="lazy" src="./assets/images/text-1.png" alt="">
            </div>
        </div>

    </div>


    <div class="dy_bg">
        <div class="container" style="padding: 80px 0 40px; text-align: center;">
            <div class="corpus_title">
                {{"技术特色" | translate }}
            </div>
            <!-- <div class="">
                多文种，训练模型定制。
            </div> -->
            <div class="datas_container row row-cols-2 row-cols-ls-4">
                <div class="col">
                    <div class="dc_item">
                        <div class="dci_img">
                            <img loading="lazy" src="./assets/images/text-pdf.png" alt="">
                        </div>
                        <div class="dci_title">
                            {{"国际通用的PDF格式" | translate}}
                        </div>
                        <div class="dci_content">
                            {{"PDF格式是国际通用标准，可以完整地保留原来的版式，精确地显示原貌，添加书签、链接、批注等。" | translate}}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="dc_item">
                        <div class="dci_img">
                            <img loading="lazy" src="./assets/images/text-img.png" alt="">
                        </div>
                        <div class="dci_title">
                            {{"良好的文件压缩比" | translate}}
                        </div>
                        <div class="dci_content">
                            {{"采用动态二值化技术，文件体积极大地压缩，图像清晰、下载速度快、文字识别率高、占用空间少。" | translate}}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="dc_item">
                        <div class="dci_img">
                            <img loading="lazy" src="./assets/images/text-pdfs.png" alt="">
                        </div>
                        <div class="dci_title">
                            {{"双层PDF的生成" | translate}}
                        </div>
                        <div class="dci_content">
                            {{"既然可以对现有的电子文件s2/ps2/ps格式进行转换，也可以将纸质资料通过扫描和OCR软件生成。" | translate}}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="dc_item">
                        <div class="dci_img">
                            <img loading="lazy" src="./assets/images/text-search.png" alt="">
                        </div>
                        <div class="dci_title">
                            {{"支持多种检索方式" | translate}}
                        </div>
                        <div class="dci_content">
                            {{"支持单字段简单查询，也能进行多字段多条件模糊检索、二次检索、复合检索。" | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>