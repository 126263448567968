import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.scss']
})
export class QualificationComponent implements OnInit { 
  name = 'news';
  language;
  url = this.api.aliApi;
  qualificationList;
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.language = localStorage.language;
    this.GetQualification();
  }

  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }

  style(color) {
    return 'background-color: ' + color + ';';
  }
  
  GetQualification(){
    this.qualificationList = this.api.GetQualification();
  }
}
