<!-- Navigation Start -->
<nav class="navbar navbar-expand-md navbar-light  sticky-top" id="nav-main" [ngClass]="{'p_top_30': language !== 'mgl'}">
    <div class="container" [ngClass]="{'max-width-1500': language === 'cy' || language === 'en'}">
        <a href="/{{language}}/home" class="navbar-brand">
            <img src="./assets/images/onon-logo-2.png" alt="斡仑科技 LOGO" class="img-fluid" />
        </a>
        <button id="nav-btn" class="navbar-toggler" data-target="#navigation" data-control="navigation" data-toggle="collapse">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navigation">
            <ul class="nav navbar-nav pull-lg-right ml-auto " [ngClass]="{'mgl-nav-flex': language === 'mgl', 'w_619': ieW }">
                <li class="nav-item sub-menu-parent">
                    <a href="{{linkLang()}}#about" class="nav-link  page-scroll" [ngClass]="{'mgl margin-right-15': language === 'mgl','font-hvlvsvn': browser === '11', 'w_74': ieW}" (click)="navbtnR()">{{"关于斡仑" | translate }}</a>
                    <ul class="sub-menu" [ngClass]="{'mgl top-78': language === 'mgl'}">
                        <li class="animate"><a [ngClass]="{'cn_text_indent': language === 'cn'}" href="{{language}}/qualification" (click)="navbtnR()">{{"资质荣誉" | translate}}</a></li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a href="{{linkLang()}}#product" class="nav-link  page-scroll" [ngClass]="{'mgl margin-right-15': language === 'mgl', 'font-hvlvsvn': browser === '11', 'w_74': ieW}" (click)="navbtnR()">{{"产品中心" | translate }}</a>
                </li>
                <li class="nav-item sub-menu-parent animate">
                    <a href="{{linkLang()}}#service" class="nav-link page-scroll" [ngClass]="{'mgl margin-right-15': language === 'mgl', 'font-hvlvsvn': browser === '11', 'w_74': ieW}" (click)="navbtnR()"> {{"技术服务" | translate }}</a>
                    <ul class="sub-menu" [ngClass]="{'top-78 mgl': language === 'mgl'}">
                        <li class="animate"><a [ngClass]="{'cn_text_indent': language === 'cn'}" href="http://help.onon.cn/" target="_blank" (click)="navbtnR()">{{"帮助文档" | translate}}</a></li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a href="{{linkLang()}}#case" class="nav-link page-scroll" [ngClass]="{'mgl margin-right-15': language === 'mgl', 'font-hvlvsvn': browser === '11', 'w_74': ieW}" (click)="navbtnR()"> {{"成功案例" | translate }}</a>
                </li>
                <li class="nav-item">
                    <a href="{{linkLang()}}#recruit" class="nav-link page-scroll" [ngClass]="{'mgl margin-right-15': language === 'mgl', 'font-hvlvsvn': browser === '11', 'w_74': ieW}" (click)="navbtnR()"> {{"招贤纳士" | translate }}</a>
                </li>
                <li class="nav-item">
                    <a href="{{linkLang()}}#news" class="nav-link page-scroll" [ngClass]="{'mgl margin-right-15': language === 'mgl', 'font-hvlvsvn': browser === '11', 'w_101': ieW}" (click)="navbtnR()"> {{"媒体报道" | translate }}</a>
                </li>
                <!-- <li class="nav-item">
                    <a href="{{linkLang()}}#cooperation" class="nav-link page-scroll" [ngClass]="{'mgl margin-right-15': language === 'mgl', 'font-hvlvsvn': browser === '11', 'w_74': ieW}" (click)="navbtnR()"> {{"合作伙伴" | translate }}</a>
                </li> -->
                <li class="nav-item">
                    <a href="#contact" class="nav-link page-scroll" [ngClass]="{'mgl margin-right-15': language === 'mgl', 'font-hvlvsvn': browser === '11', 'w_74': ieW}" (click)="navbtnR()">{{"联系我们" | translate }}</a>
                </li>
                <!-- *ngIf="!center"  -->
                <!-- <li class="nav-item sub-menu-parent animate">
                    <a class="nav-link page-scroll lang">
                        <img src="/assets/images/icon/earth.png" alt="斡仑科技 - icon">
                    </a>
                    <ul class="sub-menu2" [ngClass]="{'top-78': language === 'mgl', 'text-center': center}">
                        <li class="animate " [ngClass]="{'d-flex': center, 'justify-content-center': center}">
                            <a href="/mgl" class="mgl" (click)="navbtnR()"></a>
                        </li>
                        <li class="animate"><a (click)="navbtnR()" href="/cn">汉文</a></li>
                        <li class="animate"><a (click)="navbtnR()" href="/cy">Кирилл</a></li>
                        <li class="animate"><a (click)="navbtnR()" href="/en">English</a></li>
                    </ul>
                </li> -->
            </ul>
        </div>
        <!-- <div *ngIf="center" class="nav_language position-absolute">
            <ul>
                <li class="page-scroll lang">
                    <img src="/assets/images/icon/earth.png" alt="">
                </li>
                <li *ngIf="language !== 'cn'" class="animate"><a (click)="navbtnR()" href="/cn">汉文</a></li>
                <li *ngIf="language !== 'en'" class="animate"><a (click)="navbtnR()" href="/en">English</a></li>
                <li *ngIf="language !== 'cy'" class="animate"><span *ngIf="language !== 'en'">|</span><a (click)="navbtnR()" href="/cy">Кирилл</a></li>
                <li *ngIf="language !== 'mgl'" class="animate " [ngClass]="{'d-flex': center, 'justify-content-center': center}">
                    <a href="/mgl" class="mgl" (click)="navbtnR()"></a>
                </li>
            </ul>
        </div> -->
    </div>
</nav>
<!--Navigation End -->