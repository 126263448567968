<app-nav [name]="name"></app-nav>

<div class="cont_header_bg" [ngClass]="language === 'mgl'?'height-340':'height-266'">
    <div class="container d-flex justify-content-center position-relative" id="blog-bg">
        <h2 class="public_title" [ngClass]="{'mgl height-125': language === 'mgl'}">
            {{ "资质荣誉" | translate}}
        </h2>
    </div>
</div>

<div class="container" id="Blog-page">
    <div class="qualification_content d-flex row row-cols-1 row-cols-sm-2">

        <div class="qualification_item col" *ngFor="let item of qualificationList">
            <div class="qi_images">
                <img src="{{url}}{{item.imgUrl}}" alt="Onon Tech-资质荣誉" />
            </div>
            <div class="qi_text">
                <div class="position-relative">
                    <div class="qi_title" [ngClass]="{'qi_title_mgl mgl': language === 'mgl'}" [style]="style(item.backgroundColor)">{{ language === 'mgl'? item.mnTitle : item.title | translate}}</div>
                    <ul [ngClass]="{'mgl font-oss ul_mgl': language === 'mgl'}">
                        <li *ngFor="let it of item.content">{{ language === 'mgl' ? it.mnText : it.text }}</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>

    <!-- <div class="d-flex justify-content-center position-relative" id="blog-bg">
        <h2 class="public_title text_black" [ngClass]="{'mgl height-125': language === 'mgl'}">
            {{ "知识产权" | translate}}
        </h2>
    </div> -->

</div>