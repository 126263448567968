<app-nav></app-nav>

<div style="background-color: #fff;">
    <div class="corpus_top">
        <div class="ct_container d-flex container">
            <div class="ctc_img">
                <img loading="lazy" src="./assets/images/journal.png" alt="">
            </div>
            <div class="ctc_content">
                <div class="ctcc_title" style="color: inherit;">
                    {{"期刊审稿系统" | translate}}
                </div>
                <div class="ctcc_title_en" *ngIf="language !== 'en'">
                    Journal review system
                </div>
                <div class="ctcc_title_sub" style="color: inherit;" [innerHTML]="'jrsIntroduction' | translate">
                </div>
            </div>

        </div>

    </div>

    <br>
    <br>
    <br>
    <div class="container">
        <div class="row row-cols-2 row-cols-ls-4">
            <div class="j-i" *ngFor="let citem of contentList">
                <img src="{{citem.img}}" alt="">
                <h3 class="text-center">{{citem.title | translate}}</h3>
                <p>{{citem.content | translate}}</p>
            </div>
        </div>
    </div>

    <div class="container j-c">
        <h3>{{"期刊审稿系统流程图" |translate}}</h3>
        <div class="j-c-i">
            <img src="./assets/images/j-lct.png" alt="">

        </div>
    </div>

    <div class="container j-c">
        <h3>{{"案例展示" |translate}}</h3>
        <div class="row row-cols-1 ">
            <div class="col">
                <img src="./assets/images/j-login.png" alt="" class="j-c-it">
            </div>
            <div class="col">
                <img src="./assets/images/j-sh.png" alt="" class="j-c-it">
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
</div>