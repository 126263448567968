import { Component, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  newsList;
  mediaList = [];
  companyList = [];
  unitType ='media';
  language;
  name = 'news';
  homeHref;
  config = {
    rootMargin: '0px',
    threshold: 0,
  }
  constructor(public translateService: TranslateService, private api: ApiService, private ref: ElementRef) { }
  ngOnInit() {
    this.language = localStorage.language;
    this.homeHref = this.language + '/news/';
    this.GetNews();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }

  GetNews() {
    this.api.GetNews().subscribe(data => {
      // this.newsList = data;
      data.forEach(element => {
        if(element.textUnitName == '斡仑科技' || element.textUnitName == 'Onon科技') {
          this.companyList.push(element)
        } else {
          this.mediaList.push(element)
        }
      });
      this.newsList = this.mediaList;
    });
  }

  selectTabs(type) {
    this.unitType = type;
    if(type == 'media'){
      this.newsList = this.mediaList;
    } else {
      this.newsList = this.companyList;
    }
  }

  viewInit() {
    // var imgs = document.querySelectorAll('.img-fluid');
    var imgs = this.ref.nativeElement.querySelectorAll('.img-fluid');
    // console.log(imgs[0].src);
  }
}
