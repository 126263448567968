import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-recruit',
  templateUrl: './recruit.component.html',
  styleUrls: ['./recruit.component.scss']
})
export class RecruitComponent implements OnInit {
  language;
  name = 'recruit';
  url = this.api.aliApi;
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.language = localStorage.language;
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }
}
