<app-nav [name]="name"></app-nav>

<div class="cont_header_bg">
    <div class="container d-flex justify-content-center align-items-center">
        <h2 class="public_title" [ngClass]="{'height-125 mgl': language === 'mgl'}">
            {{"招贤纳士" | translate }}
        </h2>
    </div>
</div>

<div id="Blog-page">
    <div class="container">
        <div class="row row-cols-md-2 blog-post__content mgl-recruit-2" [ngClass]="{'row-cols_2': language !== 'mgl'}">
            <!-- 软件工程师 -->
            <div class="col-md-6" [ngClass]="{'mgl-recruit-center': language === 'mgl'}">
                <div class="recruit-img">
                    <img loading="lazy" src="{{ url }}image/recruit/img_01.jpg" alt="内蒙古斡仑科技有限公司-招聘信息图片">
                </div>
                <!-- Chinese Start -->
                <div class="padding-10" [ngClass]="language === 'mgl'?'mgl mgl-recruit height-535':'width-358'">
                    <div class="blog-head font-oss">
                        {{"软件工程师" |translate }}
                    </div>
                    <p [ngClass]="{'p_l_20': language === 'mgl'}">
                        {{"专业要求：计算机相关专业" | translate}}
                        <br> {{"学历要求：本科" |translate}}
                        <br> {{"薪资：0.6-1万" | translate}}
                        <br> {{"呼市 / 有经验者优先 / 本科 / 招4人" | translate}}
                        <br> {{"职位要求" | translate}}
                        <br>1. {{"计算机或相关专业，本科以上学历，实践能力强的可跨专业；" | translate}}
                        <br>2. {{"熟悉C、C++、.NET、JAVA、Object C、Python等任何一种或多种编程语言并有程序开发经验；" | translate}}
                        <br>3. {{"熟悉关系数据库理论，有SQL Server/MySQL/Oracle等数据库编程开发经验；" | translate}}
                        <br>4. {{"能够完成高性能、可扩展、可靠的系统软件设计与开发；" |translate}}
                        <br>5. {{"优秀的职业素质，性格开朗，团队协作能力强，能够承受工作压力；" | translate}}
                        <br>6. {{"有经验或蒙汉兼通者优先；" |translate}}
                    </p>
                </div>
                <!-- Chinese End -->
            </div>
            <!-- 自然语言处理工程师 -->
            <div class="col-md-6" [ngClass]="{'mgl-recruit-center': language === 'mgl'}">
                <div class="recruit-img">
                    <img loading="lazy" src="{{ url }}image/recruit/img_02.jpg" alt="内蒙古斡仑科技有限公司-招聘信息图片">
                </div>
                <!-- Chinese Start -->
                <div class="padding-10" [ngClass]="language === 'mgl'?'mgl mgl-recruit height-535':'width-358'">
                    <div class="blog-head font-oss">
                        {{"自然语言处理工程师" |translate }}
                    </div>
                    <p [ngClass]="{'p_l_20': language === 'mgl'}">
                        {{"专业要求：计算机相关专业" | translate}}
                        <br> {{"学历要求：本科" |translate}}
                        <br> {{"薪资：0.6-1万" | translate}}
                        <br> {{"呼市 / 经验1-3年/ 本科 / 招2人" | translate}}
                        <br> {{"职位要求" | translate}}
                        <br>1. {{"具备强悍的编码能力，有扎实的数据结构和算法功底；" | translate}}
                        <br>2. {{"精通C/C++，Java，Python等编程语言中一种或多种，对新语言有较强的学习能力；" | translate}}
                        <br>3. {{"具备机器学习、深度学习的知识基础，熟悉主流算法，了解业界最新理论和技术进展；" | translate}}
                        <br>4. {{"能熟练使用Tensorflow/Mxnet/Caffe/Theano，SyntaxNet/OpenNLP等深度学习和自然语言处理框架中任意一个优先；" |translate}}
                        <br>5. {{"参与或主导过NLP项目（如信息检索、文本分类、文本聚类、文本去重），发表过高水平学术会议论文者优先，对使用NLP技术解决实际问题尤为感兴趣者优先；" | translate}}
                        <br>6. {{"具有良好的沟通能力和责任感，能够承担工作压力，独立分析和解决问题。" |translate}}
                    </p>
                </div>
                <!-- Chinese End -->
            </div>
            <!-- 商务经理 -->
            <div class="col-md-6" [ngClass]="{'mgl-recruit-center': language === 'mgl'}">
                <div class="recruit-img">
                    <img loading="lazy" src="{{ url }}image/recruit/img_03.jpg" alt="内蒙古斡仑科技有限公司-招聘信息图片">
                </div>
                <!-- Chinese Start -->
                <div class="padding-10" [ngClass]="language === 'mgl'?'mgl mgl-recruit height-535':'width-358'">
                    <div class="blog-head font-oss">
                        {{"商务经理" |translate }}
                    </div>
                    <p [ngClass]="{'p_l_20': language === 'mgl'}">
                        {{"专业要求：无" | translate}}
                        <br> {{"学历要求：大专以上" |translate}}
                        <br> {{"薪资：面谈" | translate}}
                        <br> {{"呼市 / 有经验者优先 / 大专 / 招1人" | translate}}
                        <br> {{"职位要求" | translate}}
                        <br>1. {{"有招投标经验；" | translate}}
                        <br>2. {{"有互联网相关项目管理经验；" | translate}}
                        <br>3. {{"有驾照；" |translate}}
                        <br>5. {{"可适应出差；" | translate}}
                        <br>6. {{"独立承接项目；" |translate}}
                    </p>
                </div>
                <!-- Chinese End -->
            </div>
            <!-- 实习生 -->
            <div class="col-md-6" [ngClass]="{'mgl-recruit-center': language === 'mgl'}">
                <div class="recruit-img">
                    <img loading="lazy" src="{{ url }}image/recruit/img_06.jpg" alt="内蒙古斡仑科技有限公司-招聘信息图片">
                </div>
                <!-- Chinese Start -->
                <div class="padding-10" [ngClass]="language === 'mgl'?'mgl mgl-recruit height-535':'width-358'">
                    <div class="blog-head font-oss">
                        {{"新媒体运营实习生" |translate }}
                    </div>
                    <p [ngClass]="{'p_l_20': language === 'mgl'}">
                        {{"专业要求：无" | translate}}
                        <br> 薪资：面谈
                        <br> 呼市/有经验者优先
                        <!-- <br> {{"学历要求：无" |translate}}
                        <br> {{"呼市 / 招15人" | translate}} -->
                        <br> {{"职位要求" | translate}}
                        <br>1. {{"负责平台垂直社区内容的策划、创意、执行、发布等" | translate}}
                        <br>2. {{"参与讨论策划各种有意义的活动" | translate}}
                        <br>3. {{"参与后台运营管理，共同维护内容社区氛围" |translate}}
                        <br>我们希望你
                        <br>1.新闻编辑相关专业
                        <br>2.熟练使用办公软件及图视频设计软件
                        <br>3.网感好、情绪稳定、灵活好学
                        <br>你将得到
                        <br>1.专人带教实践能力up!
                        <br>2.领域头部企业实习证明
                        <br>3.与交付工作对应的绩效奖励
                    </p>
                </div>
                <!-- Chinese End -->
            </div>
        </div>
        <div class="padding-10 margin-0-a d-flex justify-content-center align-items-center">
            <p class="recruit-p">
                <span [ngClass]="{'mgl height-112': language === 'mgl'}">{{"请将简历寄到下列电子邮件中(请注明申请职位)" | translate }} ᠄ &nbsp;</span>
                <span>office@onon.cn</span>
                <!-- <br>{{"电话"| translate }}᠄ 0471-5216653 -->
            </p>
        </div>
    </div>
</div>