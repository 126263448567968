<app-nav>
</app-nav>
<div style="background-color: #fff;">
    <div class="corpus_top">
        <div class="ct_container d-flex container">
            <div class="ctc_content">
                <div class="ctcc_title">
                    {{ 'Onon语言资源' | translate }}
                </div>
                <div class="ctcc_title_en" *ngIf="language !== 'en'">
                    Onon Language Resources
                </div>
                <div class="ctcc_title_sub">
                    {{'corpus_introduction'| translate}}
                </div>
            </div>
            <div class="ctc_img">
                <img loading="lazy" src="{{url + 'image/corpus/corpus_top.png'}}" alt="">
            </div>
        </div>

    </div>
    <div class="container" style="padding: 80px 0 40px; text-align: center;">
        <div class="corpus_title">
            {{"单语文本语料" | translate}}
        </div>
        <!-- <div class="">
            多文种，训练模型定制。
        </div> -->
        <div class="datas_container row row-cols-2 row-cols-ls-4">
            <div class="col" *ngFor="let item of corpusList">
                <div class="dc_item">
                    <div class="dci_img">
                        <img loading="lazy" src="{{url + item.image}}" alt="">
                    </div>
                    <div class="dci_title">
                        {{item.title | translate}}
                    </div>
                    <div class="dci_content" *ngIf="language !== 'en'">
                        {{item.content | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="padding: 80px 0 40px; text-align: center;">
        <div class="corpus_title">
            {{ "多文种对齐文本语料" | translate}}
        </div>
        <!-- <div class="">
            传统蒙古文、中文、西里尔文、回鹘式蒙古文、拉丁文
        </div> -->
        <div class="datas_container row row-cols-2 row-cols-ls-4">
            <div class="col" *ngFor="let aitem of alignList">
                <div class="dc_item">
                    <div class="dci_img">
                        <img loading="lazy" src="{{url + aitem.image}}" alt="">
                    </div>
                    <div class="dci_title">
                        {{aitem.title | translate}}
                    </div>
                    <div class="dci_content" *ngIf="language !== 'en'">
                        {{aitem.content}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="padding: 80px 0 40px; text-align: center;">
        <div class="corpus_title">
            {{"语音识别语料采集加工" | translate}}
        </div>
        <!-- <div class="">
            Onon语言资源自有版，200000小时音频数据，数据质量值得信赖。
        </div> -->
        <div class="datas_container row row-cols-2 row-cols-ls-4">
            <div class="col" *ngFor="let ditem of dataSet">
                <div class="dc_item">
                    <div class="dci_img">
                        <img loading="lazy" src="{{url + ditem.image}}" alt="">
                    </div>
                    <div class="dci_title">
                        {{ditem.title |translate}}
                    </div>
                    <div class="dci_content" *ngIf="language !== 'en'">
                        {{ditem.content}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="padding: 80px 0 40px; text-align: center;">
        <div class="corpus_title">
            {{"语音合成语料加工" | translate}}
        </div>
        <!-- <div class="">
            Onon语言资源自有版，200000小时音频数据，数据质量值得信赖。
        </div> -->
        <div class="datas_container row row-cols-2 row-cols-ls-4">
            <div class="col" *ngFor="let yyitem of yyhcList">
                <div class="dc_item">
                    <div class="dci_img">
                        <img loading="lazy" src="{{url + yyitem.image}}" alt="">
                    </div>
                    <div class="dci_title">
                        {{yyitem.title |translate}}
                    </div>
                    <div class="dci_content" *ngIf="language !== 'en'">
                        {{yyitem.content}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="padding: 80px 0 40px; text-align: center;">
        <div class="corpus_title">
            {{"计算机视觉语料" | translate}}
        </div>
        <div class="datas_container row row-cols-2 row-cols-ls-4">
            <div class="col" *ngFor="let vitem of visualList">
                <div class="dc_item">
                    <div class="dci_img">
                        <img loading="lazy" src="{{url + vitem.image}}" alt="">
                    </div>
                    <div class="dci_title">
                        {{vitem.title | translate}}
                    </div>
                    <div class="dci_content" *ngIf="language !== 'en'">
                        {{vitem.content}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dy_bg">
        <div class="container" style="padding: 80px 0">
            <div class="d-flex auto_box authorize_box">
                <div class="dy_img">
                    <img loading="lazy" src="{{ url + 'image/corpus/corpus_bottom.png'}}" alt="">
                </div>
                <div class="dy_content">
                    <div class="corpus_title" style="margin-bottom: 24px;">
                        {{"服务" | translate}}
                    </div>
                    <div>
                        <div class="sq_content" innerHTML="{{'serveIntroduction' | translate}}">

                        </div>
                        <ul>
                            <li class="sq_li li_title">{{"基于语料的预训练模型的授权" | translate}}</li>
                            <li class="sq_li li_title">{{"数据产品购买" | translate}}</li>
                            <li class="sq_li li_title">{{"数据定制服务" | translate}}</li>
                            <li class="sq_li li_title">{{"数据采集" | translate}}</li>
                            <li class="sq_li li_title">{{"数据加工" | translate}}</li>
                            <li class="sq_li li_title">{{"数据标注" | translate}}</li>
                            <li class="sq_li li_title">{{"编码转换" | translate}}</li>
                            <li class="sq_li li_title">{{"校对审核" | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container" style="padding: 80px 0">
        <div class="d-flex auto_box">
            <div class="dy_img">
                <img loading="lazy" src="{{ url + 'image/corpus/corpus02.png'}}" alt="">
            </div>
            <div class="dy_content">
                <div class="corpus_title">
                    计算机视觉
                </div>
                <div>
                    <div class="sq_content"></div>
                    <ul>
                        <li class="sq_li li_title">蒙古文手写体数据</li>
                        <li class="sq_li li_title">蒙汉自然场景OCR数据</li>
                        <li class="sq_li li_title">蒙汉牌匾OCR数据</li>
                        <li class="sq_li li_title">多语言自然场景OCR数据</li>
                        <li class="sq_li li_title">蒙古文PPT OCR数据</li>
                        <li class="sq_li li_title">蒙古文试卷采集数据</li>
                        <li class="sq_li li_title">蒙古文书籍采集数据</li>
                        <li class="sq_li li_title">蒙古文字幕视频</li>
                        <li class="sq_li li_title">西里尔文字幕视频</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->

    <!-- style 1 -->
    <!-- <div class="dy_bg">
        <div class="container dy_bg" style="padding: 80px 0 ;">
            <div class="flex_center">
                <div class="corpus_title">
                    单语文本语料
                </div>
            </div>
            <div class="row row-cols-3 row-cols-ls-4">
                <div class="col" *ngFor="let item of corpusList">
                    <div class="dy_item">
                        <div class="dy_icon">
                            <img loading="lazy" src="{{url + item.image}}" alt="">
                        </div>
                        <div class="dy_title">
                            {{item.title}}
                        </div>
                        <div class="dy_number" [innerHTML]="item.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- style 2 -->
    <!-- <div class="container" style="padding: 80px 0">
        <div class="d-flex auto_box">
            <div class="dy_content">
                <div class="corpus_title">
                    多文种对齐文本语料
                </div>
                <div>
                    <div class="sq_content">
                        传统蒙古文、中文、西里尔文、回鹘式蒙古文、拉丁文
                    </div>
                    <ul>
                        <li class="sq_li li_title">传统蒙古文与中文对齐语料</li>
                        <li class="sq_li">200万组</li>
                        <li class="sq_li li_title">传统蒙古文与西里尔文对齐语料</li>
                        <li class="sq_li">200万组</li>
                        <li class="sq_li li_title">传统蒙古文与英文对齐语料</li>
                        <li class="sq_li">200万组</li>
                        <li class="sq_li li_title">传西里尔文与中文对齐文本语料</li>
                        <li class="sq_li">200万组</li>
                        <li class="sq_li li_title">传统蒙古文与回鹘式蒙古文文本</li>
                        <li class="sq_li">200万组</li>
                        <li class="sq_li li_title">传统蒙古文与拉丁文</li>
                        <li class="sq_li">200万组</li>
                    </ul>
                </div>
            </div>
            <div class="dy_img">
                <img loading="lazy" src="{{ url + 'image/corpus/corpus03.png'}}" alt="">
            </div>
        </div>
    </div> -->
</div>