import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-textualization',
  templateUrl: './textualization.component.html',
  styleUrls: ['./textualization.component.scss']
})
export class TextualizationComponent implements OnInit {
  language;
  constructor(private api:ApiService,private router: Router) { }

  ngOnInit(): void {
    if (this.router.url.slice(1, 3) === 'mg') {
      this.language = 'mgl';
    } else {
      this.language = this.router.url.slice(1, 3);
    }
  }
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }

}
