<app-nav></app-nav>

<div style="background-color: #fff;">
    <div class="corpus_top">
        <div class="ct_container d-flex container">
            <div class="ctc_content">
                <div class="ctcc_title" style="color: inherit;">
                    {{"新媒体代运营服务" | translate}}
                </div>
                <div class="ctcc_title_en" *ngIf="language !== 'en'">
                    New media agency operation services
                </div>
                <div class="ctcc_title_sub" style="color: inherit;margin-right: 16px;">
                    {{'mediaIntroduction' | translate}}
                </div>
            </div>
            <div class="ctc_img">
                <img loading="lazy" src="./assets/images/m-1.png" alt="">
            </div>
        </div>

    </div>


    <div class="mc_content d-flex container">
        <div class="mcc_img_1">
            <img src="./assets/images/media-1.png" alt="斡仑科技-新媒体代运营服务">
        </div>
        <div class="mcc_content">
            <h2 class="mc_title">{{"多矩阵媒体"| translate}}</h2>
            <p>{{"可提供微信公众号、微信、抖音等媒体矩阵的运营服务。"| translate}}</p>
            <p>{{"新媒体内图文更符合“读屏习惯”，让读者快速捕捉重点，提升完成阅读量及推荐转发。"| translate}}</p>
        </div>

    </div>
    <div class="mc_content container">
        <div class="row row-cols-ls-4 row-cols-2">
            <div class="col mcc_iimg">
                <img src="./assets/images/media-2.png" alt="">
            </div>
            <div class="col mcc_iimg">
                <img src="./assets/images/media-3.png" alt="">
            </div>
            <div class="col mcc_iimg">
                <img src="./assets/images/media-4.png" alt="">
            </div>
            <div class="col mcc_iimg">
                <img src="./assets/images/media-5.png" alt="">
            </div>
        </div>



    </div>

    <div class="mc_content container">
        <div class="d-flex mcc_b" style="justify-content: center; align-items: center;">
            <p>{{"根据账号定位及单位性质设计多个背景模板、头图、底部引导图、主次封面图、设计竖版政策解读。"| translate}}</p>
            <div class="mcc_iimg_b">
                <img src="./assets/images/media-6.png" alt="">
            </div>
        </div>
    </div>

    <br>
    <br>
    <br>










</div>