<div class="footer" id="contact">
    <div class="container d-flex flex-wrap justify-content-between">
        <div class="footer-left">
            <div class="contact-information d-flex flex-column">
                <div class="d-flex">
                    <h2 class="public_title" [ngClass]="{'mgl float-left height-125': language === 'mgl'}">
                        {{"联系我们" | translate }}
                    </h2>
                </div>
                <!-- Contact Left Start -->
                <div class="contact-us-single-item d-flex">
                    <div class="contact-icon">
                        <img loading="lazy" src="/assets/images/icon/cal.png" alt="内蒙古斡仑科技有限公司-cal" />
                    </div>
                    <ul class="working-hour ">
                        <li class="d-flex justify-content-between">
                            <div class="work-day"> <span [ngClass]="{'mgl h-40px': language === 'mgl'}">{{"周一至周五"| translate}}</span></div>
                            <div class="work-time">9:00 - 18:00</div>
                        </li>
                    </ul>
                </div>
                <div class="contact-us-single-item d-flex">
                    <div class="contact-icon width-18" style="display: inline-table;">
                        <img loading="lazy" src="/assets/images/icon/map.png" alt="内蒙古斡仑科技有限公司-map" />
                    </div>
                    <div class="work-info position_hover" [ngClass]="{'mgl height-66 hover_tb-lr': language === 'mgl','hover_lr': language === 'cn'}" (click)="OpenPosition()">
                        {{"内蒙古自治区呼和浩特市回民区大学西街71号银都大厦B座801-803室"| translate}}
                    </div>
                </div>
                <div class="contact-us-single-item d-flex">
                    <div class="contact-icon">
                        <img loading="lazy" src="../assets/images/icon/at.png" alt="内蒙古斡仑科技有限公司-at" />
                    </div>
                    <div class="work-info">dev@onon.cn</div>
                </div>
                <div class="contact-us-single-item d-flex">
                    <div class="contact-icon">
                        <img loading="lazy" src="../assets/images/icon/tel.png" alt="内蒙古斡仑科技有限公司-tel" />
                    </div>
                    <div class="work-info">0471-5216653</div>
                </div>
                <div class="contact-us-single-item d-flex">
                    <div class="work-info" style="padding-left: 0;">{{'关注我们' | translate}}</div>
                </div>
                <div class="contact-us-single-item d-flex">
                    <div class="gz_icon _wx">
                        <div class="gz_img">
                            <img loading="lazy" src="./assets/images/gz.jpg" alt="onon-微信公众号">
                        </div>
                    </div>
                    <div class="gz_icon _sph">
                        <div class="gz_img">
                            <img loading="lazy" src="./assets/images/QRcode/sph_QRcode.png" alt="onon-微信视频号">
                        </div>
                    </div>
                    <div class="gz_icon _xhs">
                        <div class="gz_img">
                            <img loading="lazy" src="./assets/images/QRcode/xhs_QRcode.png" alt="onon-小红书">
                        </div>
                    </div>
                    <div class="gz_icon _dy">
                        <div class="gz_img">
                            <img loading="lazy" src="./assets/images/QRcode/dy_QRcode.png" alt="onon-抖音号">
                        </div>
                    </div>
                    <div class="gz_icon _bl">
                        <div class="gz_img">
                            <img loading="lazy" src="./assets/images/QRcode/bl_QRcode.png" alt="onon-B站">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-right d-flex flex-column " [ngClass]="{'w_352': width && language === 'mgl'}">
            <div class="footer-right-cont" [ngClass]="{mgl: language === 'mgl'}">
                <ul class="d-flex flex-wrap footer-title " [ngClass]="{'footer-right-cont-mgl': language === 'mgl'}">
                    <li class="margin-0-10">
                        {{"产品中心" | translate }}
                        <ul>
                            <li><a href="https://doc.onon.cn/" target="_blank">{{"Onon文库" | translate}}</a></li>
                            <li><a href="https://map.onon.cn/" target="_blank">{{"Onon地图" | translate}}</a></li>
                            <li><a href="http://office.onon.cn/" target="_blank">{{"Onon办公套装" | translate}}</a></li>
                            <li><a href="http://ime.onon.cn/" target="_blank">{{"Onon输入法" | translate}}</a></li>
                            <li><a href="/{{language}}/#product">{{"了解更多"| translate}}>></a></li>
                        </ul>
                    </li>
                    <li class="margin-0-10">
                        {{"技术服务" | translate }}
                        <ul>
                            <li><a href="http://help.onon.cn" target="_blank">{{"帮助文档" | translate}}</a></li>
                            <li></li>
                        </ul>
                    </li>
                    <li class="margin-0-10">
                        {{"关于我们" | translate}}
                        <ul>
                            <li><a href="/{{language}}/recruit">{{"加入我们" | translate }}</a></li>
                            <li><a href="/{{language}}/news">{{ "媒体报道" | translate}}</a></li>
                            <li><a href="/{{language}}/qualification">{{ "资质荣誉" | translate}}</a></li>
                            <li><a href="/{{language}}/legalNotices">{{"法律声明"| translate}}</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="margin-20-0">
                <ul class="footer-title d-flex flex-column">
                    <li class="margin-0-10" [ngClass]="{mgl: language === 'mgl'}">
                        {{"微信小程序"| translate}}
                        <ul class="footer-title" [ngClass]="{'d-flex': language !== 'mgl'}">
                            <li class="d-flex flex-column">
                                <span>{{"Onon文库"| translate}}</span>
                                <div class="QR_code">
                                    <img loading="lazy" src="{{url}}image/product/doc_QRcode.jpg" alt="内蒙古斡仑科技有限公司-OnonDoc">
                                </div>
                            </li>
                            <li class="d-flex flex-column" [ngClass]="{'margin-0-10': language !== 'mgl'}">
                                <span>{{"Onon地图"| translate}}</span>
                                <div class="QR_code">
                                    <img loading="lazy" src="{{url}}image/product/map_QRcode.jpg" alt="内蒙古斡仑科技有限公司-OnonMap">
                                </div>
                            </li>
                            <!-- <li *ngIf="language !== 'mgl'" class="d-flex flex-column">
                                <span><a href="/{{language}}#applet">{{"更多"| translate}}>></a></span>
                            </li> -->
                        </ul>
                    </li>
                    <!-- *ngIf="language === 'mgl'" -->
                    <li class="margin-0-10 " [ngClass]="{ 'mgl': language === 'mgl'}">
                        <span *ngIf="language === 'mgl'"> &nbsp;</span>
                        <ul class="footer-title " [ngClass]="{'d-flex': language !== 'mgl'}">
                            <li class="d-flex flex-column" [ngClass]="{'margin-10-0': language === 'mgl'}">
                                <span>{{"Onon输入法"| translate}}</span>
                                <div class="QR_code">
                                    <img loading="lazy" src="{{url}}image/product/ime_QRcode.jpg" alt="内蒙古斡仑科技有限公司-Onon输入法">
                                </div>
                            </li>
                            <li class="d-flex flex-column" [ngClass]="{'margin-0-10': language !== 'mgl','margin-10-0': language === 'mgl'}">
                                <span>{{"Onon办公助手"| translate}}</span>
                                <div class="QR_code">
                                    <img loading="lazy" src="{{url}}image/product/officeAssistant_QRcode.jpg" alt="内蒙古斡仑科技有限公司-Onon办公助手">
                                </div>
                            </li>
                        </ul>
                    </li>
                    <!-- <li class="margin-0-10 " [ngClass]="{ 'mgl': language === 'mgl'}">
                        <span *ngIf="language === 'mgl'"> &nbsp;</span>
                        <ul class="footer-title " [ngClass]="{'d-flex': language !== 'mgl'}">
                            <li class="d-flex flex-column" [ngClass]="{'margin-10-0': language === 'mgl'}">
                                <span>{{"Onon习语"| translate}}</span>
                                <div class="QR_code">
                                    <img loading="lazy" src="{{url}}image/product/heleqe_QRcode.jpg" alt="内蒙古斡仑科技有限公司-Onon习语">
                                </div>
                            </li>
                        </ul>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="container position-relative">
    <div class="foot-info d-flex flex-wrap justify-content-between align-items-center">
        <div class="foot-logo">
            <a href="/{{language}}/home">
                <img loading="lazy" src="/assets/images/onon-logo-2.png" alt="内蒙古斡仑科技有限公司-Logo" />
            </a>
        </div>
        <div class="foot-text text-center">
            <div style="max-width: 400px;" [ngClass]="{'margin-bottom-20': language === 'mgl'}"><span class="height-29" [ngClass]="{mgl: language === 'mgl'}">{{"内蒙古斡仑科技有限公司"| translate}}</span></div>
            <div>©{{ date | date: 'yyyy' }}&nbsp;&nbsp;蒙ICP备18002860</div>
        </div>
        <div class="f_d">
            <img loading="lazy" class="footer-erwei" src="/assets/images/gz.jpg" alt="内蒙古斡仑科技有限公司-微信公众号">
        </div>
    </div>
</div>