import { Component, OnInit } from '@angular/core';
import { NgxGlideComponent } from 'ngx-glide';
import { ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { relayStylePagination } from '@apollo/client/utilities';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  language;
  url = this.api.aliApi;
  open1 = false;
  serviceImg = true;
  perView = 3;
  left = '〈';
  right = '〉';
  IE = '';
  homeHref;

  slides = [];
  productSliderList;
  ProductList;
  productList;
  appletList;
  successList;
  newsList = [];
  userData;
  internetService;
  office;
  languageIndustry;
  serviceList;
  GetNoAccessServiceList;
  @ViewChild(NgxGlideComponent, { static: false }) ngxGlide: NgxGlideComponent;
  constructor(private api: ApiService) {
  }

  play(): void {
    this.ngxGlide.play();
  }

  ngOnInit() {
    this.language = localStorage.language;
    this.homeHref = this.language + '/news/';
    this.IE = localStorage.browser;
    if ( window.innerWidth < 992) {
      this.serviceImg = false;
    }
    if (window.innerWidth < 768) {
      this.perView = 2;
    }
    if (window.innerWidth <= 1024) {
     this.smallSliderData(this.language);
    } else {
      this.sliderData(this.language);
    }
    this.GetProduct();
    this.GetInternetService();
    this.GetOffice();
    this.GetLanguageIndustry();
    this.GetProductList();
    this.GetproductList();
    this.GetWechatAppletList();
    this.GetSuccessList();
    this.GetNews();
    this.GetCooperation();
    this.GetService();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    
    setTimeout(() => {
      this.api.AddNotes();
    }, 100);
    // <ruby><span>斡</span><rt>wò</rt></ruby><ruby><span>仑</span><rt>lún</rt></ruby>
  }

  openIcon() {
    const wi = document.getElementById('wi');
    if (!this.open1) {
      wi.style.display = 'block';
      this.open1 = ! this.open1;
    } else {
      wi.style.display = 'none';
      this.open1 = ! this.open1;
    }
  }

  //轮播图
  sliderData(lang) {
    if (lang === 'mgl') {
      // this.slides = this.api.sliderList.mgl
      this.slides = this.api.GetSliderList().mgl;
    } else  if (lang === 'cn') {
      // this.slides = this.api.sliderList.cn
      this.slides = this.api.GetSliderList().cn;
    } else if ( lang === 'en') {
      // this.slides = this.api.sliderList.en
      this.slides = this.api.GetSliderList().en;
    } else if ( lang === 'cy') {
      // this.slides = this.api.sliderList.cy
      this.slides = this.api.GetSliderList().cy;
    }
  }

  //小屏轮播图
  smallSliderData(lang) {
    if (lang === 'mgl') {
      // this.slides = this.api.smallSliderList.mgl;
      this.slides = this.api.GetSmallSliderList().mgl;
    } else  if (lang === 'cn') {
      // this.slides = this.api.smallSliderList.cn;
      this.slides = this.api.GetSmallSliderList().cn;
    } else if ( lang === 'en') {
      // this.slides = this.api.smallSliderList.en;
      this.slides = this.api.GetSmallSliderList().en;
    } else if ( lang === 'cy') {
      // this.slides = this.api.smallSliderList.cy;
      this.slides = this.api.GetSmallSliderList().cy;
    }
  }

  //技术服务
  GetProduct(){
    this.productSliderList = this.api.GetProduct();
  }

  //产品中心（已发布）
  GetProductList(){
    this.ProductList = this.api.GetProductList();
  }

  //产品中心（未发布） 
  GetproductList() {
    if (window.innerWidth > 768) {
      this.productList =     this.api.GetproductList();
    }
  }

  GetInternetService() {
    this.internetService = this.api.GetInternetService();
  }

  GetOffice() {
    this.office = this.api.GetOffice();
  }

  GetLanguageIndustry() {
    this.languageIndustry = this.api.GetlanguageIndustry();
  }

  GetService() {
    let list = this.api.GetServiceList()
    this.serviceList = list;
  }

  GetNoAccessService() {
    this.GetNoAccessServiceList = this.api.GetNoAccessService();
  }

  //微信小程序
  GetWechatAppletList(){
    this.appletList = this.api.GetWechatAppletList();
  }

  //成功案例
  GetSuccessList(){
    this.successList = this.api.GetSuccessList();
  }

  //媒体报道
  GetNews() {
    this.api.GetNews().subscribe(data => {
      for(let i = 0; i<2; i++) {
        this.newsList[i] = data[i];
      }
  });
  }

  //合作伙伴
  GetCooperation() {
    this.userData = this.api.GetCooperation();
  }
}