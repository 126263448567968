<div class="sidebar">
    <div class="sidebar_item position-relative">
        <a (click)="openIcon()">
            <img src="/assets/images/icon/wechat_icon.png" alt="内蒙古斡仑科技 - 微信公众号" />
            <div *ngIf="!open1" class="position-absolute sidebar_Tooltip" [ngClass]="{'mgl': language === 'mgl'}">
                {{"微信公众号" | translate }}
            </div>
        </a>
        <div class="popover" id="wi">
            <img class="wechat" src="/assets/images/gz.jpg" alt="Onon官网 - 微信公众号">
        </div>
    </div>
    <hr class="sidebar_hr">
    <div class="sidebar_item position-relative">
        <a href="http://office.onon.cn/zh-CN/academy" target="_blank">
            <img src="/assets/images/icon/ononSchool_icon.png" alt="内蒙古斡仑科技 - onon office - 斡仑学院" />
            <div class="position-absolute sidebar_Tooltip" [ngClass]="{'mgl': language === 'mgl'}">
                {{"斡仑学院" | translate }}
            </div>
        </a>
    </div>
</div>

<!--BackgroundLines Start-->
<!-- <div class="page-wrapper">
    <div class="page-line">
        <div class="container">
            <div class="page-line__inner row">
                <div class="page-col col-lg-6"></div>
                <div class="page-col col-lg-6"></div>
            </div>
        </div>
    </div>
</div> -->
<!--BackgroundLines End-->

<router-outlet></router-outlet>

<app-footer></app-footer>