import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  language;
  open1 = false;
  constructor(private router: Router, public translateService: TranslateService) { }

  ngOnInit(): void {
    // url => setLanguage,localStorage.language
    this.setLanguage(this.router.url.slice(1, 3));
    if (this.router.url.slice(1, 3) === 'mg') {
      localStorage.language = 'mgl';
    } else {
      localStorage.language = this.router.url.slice(1, 3);
    }
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }

  openIcon() {
    const wi = document.getElementById('wi');
    if (!this.open1) {
      wi.style.display = 'block';
      this.open1 = ! this.open1;
    } else {
      wi.style.display = 'none';
      this.open1 = ! this.open1;
    }
  }
  
  setLanguage(lang) {
    if (lang === 'mg') {
      this.translateService.setDefaultLang('mgl');
      this.language = lang;
      localStorage.language = 'mgl';
    } else  if (lang === 'cn') {
      this.translateService.setDefaultLang('cn');
      this.language = lang;
      localStorage.language = lang;
    } else if ( lang === 'en') {
      this.translateService.setDefaultLang('en');
      this.language = lang;
      localStorage.language = lang;
    } else if (lang === 'cy') {
      this.translateService.setDefaultLang('cy');
      this.language = lang;
      localStorage.language = lang;
    } else {
      console.log('lang:' + lang);
      console.log('ERROR: language null!');
    }
  }
}
