<app-nav></app-nav>
<!-- <div class="cms_top">
    <div class="cms_top_title" [@box]="animation ? 'open' : 'closed'">
        <h2 class="public_title">
            融媒体资源管理系统
            软件定制开发
        </h2>
        <div class="sub_title">
            多站点，多语言，操作简便，高效查询，便于管理的网站系统
        </div>
    </div>
</div> -->

<div class="corpus_top" style="background-color: #fff;">
    <div class="ct_container d-flex container">
        <div class="ctc_content">
            <div class="ctcc_title" style="color: inherit;">
                {{"软件定制开发" | translate}}
            </div>
            <div class="ctcc_title_en" *ngIf="language !== 'en'">
                Software custom development
            </div>
            <div class="ctcc_title_sub" style="color: inherit;">
                {{"多站点，多语言，操作简便，高效查询，便于管理的网站系统" | translate}}
            </div>
        </div>
        <div class="ctc_img">
            <img loading="lazy" src="./assets/images/cms-1.png" alt="">
        </div>
    </div>

</div>

<div class="cms_container">
    <div class="container">
        <div class="d-flex justify-content-center">
            <h2 class="public_title text_black" style="padding-bottom: 32px;">
                {{"优势 / 特点"| translate}}
            </h2>
        </div>
        <div class="cms_advantage row row-cols-2 row-cols-lg-3">
            <div class="ca_item col" *ngFor="let item of advantageList">
                <div class="ca_item_img">
                    <img loading="lazy" src="{{item.image}}" alt="">
                </div>
                <h4 class="ca_item_title">
                    {{item.title | translate}}
                </h4>
                <div class="ca_item_text">
                    {{item.content | translate}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="cms_container bg-light">
    <div class="container">
        <div class="d-flex justify-content-center">
            <h2 class="public_title text_black" style="padding-bottom: 32px;">
                {{"成功案例" | translate}}
            </h2>
        </div>
        <div class="cms_successCase row row-cols-2 row-cols-lg-3">
            <a href="{{sitem.link}}" target="_black" class="cs_item col" *ngFor="let sitem of cmsSuccessCaseList">
                <div class="cs_item_img">
                    <img loading="lazy" src="{{sitem.image}}" alt="">
                </div>
                <div class="cs_item_title">
                    {{sitem.title | translate}}
                </div>
            </a>
        </div>
    </div>
</div>