<app-nav [name]="name"></app-nav>

<div class="cont_header_bg" style="height: 350px;">
</div>

<div id="Blog-page" class="blog-inner-page">
    <div class="container">
        <div class="row">
            <div class="blog-grid-card col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between flex-column">
                <div class="blog-img">
                    <img src="https://onon-guanwang.oss-cn-beijing.aliyuncs.com/{{ newsContent.images }}" alt="Blog Image" class="img-fluid" />
                </div>
                <div *ngIf="language !== 'mgl'" class="d-flex align-items-center blog-inner" [ngClass]="{'h-125': language === 'mgl'}">
                    <!-- <h4 *ngIf="language === 'mgl'" class="mgl" style="overflow-x: auto;">
                        {{ newsContent.title }}
                    </h4> -->
                    <h4 *ngIf="language === 'cn'">
                        {{ newsContent.textChineseTitle }}
                    </h4>
                </div>
            </div>
        </div>

        <div *ngIf="language === 'mgl'" id="mglNews" class="blog-post-wrapper overflow-x-a h-535 d-flex">
            <div class="mgl" style="margin: 0 15px;">
                <div>
                    <h4>{{ newsContent.title }}</h4>
                </div>
                <div>{{ newsContent.textMUnitName }}</div>
                <div style="text-align: end;">{{ newsContent.dateStart.slice(0,10)}}</div>
            </div>
            <div class="blog-post__content mgl_news_text d-flex flex-column mgl" [innerHTML]="newsContent.htmlBodyMongolian">
            </div>
        </div>
        <div *ngIf="language !== 'mgl'" class="blog-post-wrapper" [ngClass]="{'overflow-x-a h-535': language === 'mgl'}">
            <div style="margin: 15px 0;">
                <div>{{ newsContent.textUnitName }}</div>
                <div style="text-align: end;">{{ newsContent.dateStart.slice(0,10)}}</div>
            </div>
            <div class="blog-post__content" [innerHTML]="newsContent.htmlBodyContent">
            </div>
        </div>
        <!-- blog-post-wrapper End -->
        <!-- <div class="text_link" [ngClass]="{'mgl': language === 'mgl', 'height-75': language === 'mgl'}" style="margin-bottom: 80px;">
            <a href="{{newsContent.textLink}}" target="_blank" [ngClass]="{'a_overline': language === 'mgl'}">{{"ᠡᠬᠡ ᠪᠢᠴᠢᠭ ᠦᠨ ᠴᠥᠷᠬᠡᠯᠡᠭᠡ" | translate }}</a>
        </div> -->
    </div>
</div>