import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('name')  component;
  language;
  languageText;
  center = true;
  browser;
  ieW = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.language = localStorage.language;
    this.browser = localStorage.browser;
    const bw = document.body.clientWidth;
    if (bw < 767.98) {
      this.center = false;
    }

    if ( this.browser !== -1 && this.language === 'mgl') {
      this.ieW = true;
      // console.log( true );
    }
    // console.log( this.browser );
    // console.log( this.language );
  }
  navbtnR() {
    const bw = document.body.clientWidth;
    const nb = document.getElementById('nav-btn');
    if ( bw <  767.98) {
        nb.click();
    }
    if (this.browser === 11) {
      location.reload();
    }
  }
  linkLang() {
    if (this.component === 'news' || this.component === 'recruit') {
    const s = '/' + this.language + '/';
    return s;
    } else {
      return;
    }
  }
}
