import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-cms',
  animations:[
    trigger('box', [
      // ...
      state('open', style({
        transform: 'translateY(0px)',
        opacity: 1,
      })),
      state('closed', style({
        transform: 'translateY(30px)',
        opacity: 0.8,
      })),
      transition('open <=> closed', [
        animate('.8s')
      ])
    ]),
  ],
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  language;
  animation = false
  advantageList= [
    {
      image: './assets/images/advantage/dzd.png',
      title: '多站点 / 网站群与集群部署',
      content: '即一套程序、一个应用程序域部署多个站点'
    },
    {
      image: './assets/images/advantage/app.png',
      title: '支持多终端',
      content: '可为微信小程序、App等终端提供数据'
    },
    {
      image: './assets/images/advantage/dyy.png',
      title: '多语言',
      content: '支持蒙古文、中文、英文等多种语言轻松打造国际站'
    },
    {
      image: './assets/images/advantage/ycc.png',
      title: '云存储',
      content: '使用OSS对象存储对媒体文件和办公软件多一份保障'
    },
    {
      image: './assets/images/advantage/xys.png',
      title: '响应式',
      content: '兼容各种移动端'
    },
    {
      image: './assets/images/advantage/search.png',
      title: '搜索引擎',
      content: '全局检索，针对蒙古文提供标准编码搜索和形搜索'
    },
    {
      image: './assets/images/advantage/sjk.png',
      title: '数据库',
      content: '支持MySQL，sql server，SQLite等多个数据库'
    },
    {
      image: './assets/images/advantage/sjk.png',
      title: '国产化适配',
      content: '国产服务器与操作系统部署'
    }
  ];
  cmsSuccessCaseList = [
    {
      link: 'http://geser.onon.cn/',
      image: 'https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/success/hsr.jpg',
      title: '巴林《格斯尔》数据库'
    },
    {
      link: 'http://nomiinoboo.com/',
      image: 'https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/cms/sab.png',
      title: '书敖包'
    },
    {
      link: 'http://39.99.62.216:8003/',
      image: 'https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/cms/blwk.png',
      title: '水晶珠书屋'
    },
    {
      link: 'http://office.onon.cn/',
      image: 'https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/cms/office.png',
      title: 'Onon Office'
    },
    {
      link: 'http://ime.onon.cn/',
      image: 'https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/cms/ime.png',
      title: 'Onon输入法'
    },
    {
      link: 'http://help.onon.cn/',
      image: 'https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/cms/help.png',
      title: 'Onon帮助文档'
    },
    {
      link: 'http://39.99.62.216:8017/',
      image: 'https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/cms/yry.png',
      title: '鄂托克旗蒙古族幼儿园'
    },
    {
      link: 'http://39.99.62.216:8071/',
      image: 'https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/success/minge.jpg',
      title: '乌拉特民歌数字博物馆'
    },
  ]
  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.router.url.slice(1, 3) === 'mg') {
      this.language = 'mgl';
    } else {
      this.language = this.router.url.slice(1, 3);
    }
  }
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    let callback =(entries, observer) => {
      entries.forEach(entry => {
        // Each entry describes an intersection change for one observed target element:
        // entry.boundingClientRect
        // entry.intersectionRatio
        // entry.intersectionRect
        // entry.isIntersecting
        // entry.rootBounds
        // entry.target
        // entry.time
        this.animation = true;
        box.unobserve(entry.target);
      });
    };
    var box = new IntersectionObserver(callback, {
      rootMargin: '0px',
      threshold: 1.0
    });
    let element = document.querySelector('.cms_top_title');
    box.observe(element);

  }
  

  toggle() {
    this.animation = !this.animation;
  }

}
