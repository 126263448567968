<!-- <div id="hundredMg">
    <img src="https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/other/100_mg.jpg" alt="">
    <span onclick="javascript:document.getElementById('hundredMg').style.display = 'none';">x</span>
</div> -->
<!-- <div id="hundredCn">
    <a href="https://www.cnipa.gov.cn/col/col2859/index.html" target="_blank">
        <img src="https://onon-guanwang.oss-cn-beijing.aliyuncs.com/image/other/2022zccq.jpg" alt="">
    </a>
    <span onclick="javascript:document.getElementById('hundredCn').style.display = 'none';">x</span>
</div> -->
<!-- <style>
    #hundredMg {
        position: fixed;
        top: 180px;
        left: 0;
        z-index: 9999;
        width: 104px;
        height: 414px;
        text-align: right;
    }
    
    #hundredCn {
        position: fixed;
        top: 180px;
        right: 0;
        z-index: 9999;
        width: 104px;
        height: 414px;
    }
    
    #hundredMg span,
    #hundredCn span {
        cursor: pointer;
        color: #9e9e9e;
        border: 1px solid #e0e0e0;
        padding: 0 3px;
    }
    
    #hundredMg img,
    #hundredCn img {
        width: 100%;
    }
    
    @media (max-width: 768px) {
        #hundredMg,
        #hundredCn {
            display: none !important;
        }
    }
</style> -->

<app-nav></app-nav>

<!-- slide  Start  -->
<div class="hero-section">
    <!-- Hero Slider Start  -->
    <div class="header hero-slider">
        <!-- Swiper Container Start  -->
        <div class="swiper-container">
            <!-- Swiper Wrapper Start  -->
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of slides">
                    <div class="slide-inner bg-image" style="background-image: url({{url}}{{item.imgUrl}});">
                        <!-- <div class="container">
                            <div class="carousel-detail d-flex align-items-center " data-swiper-parallax="-900">
                                <div class="carousel-detail-animate">
                                    <span class="down-up" [ngClass]="{mgl: language === 'mgl'}">        </span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- Swiper Wrapper End  -->
        </div>
        <!-- Swiper Container End  -->
    </div>
    <!-- Hero Slider End  -->
    <!--news Bottom Start-->
    <div class="hero-bottom">
        <div class="container">
            <div class="hero-inner row">
                <div class="hero-scroll d-flex align-items-center justify-content-center col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <span></span>
                    <a href="#about" class="page-scroll d-flex justify-content-center align-items-center scroll">
                        <img loading="lazy" src="/assets/images/icon/arrow-down.png" alt="斡仑科技 - Down Arrow" />
                    </a>
                </div>
                <!--Hero Scroll End-->
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 swiper-bg">
                    <div class="swiper-pagination swiper-pagination-fraction"><span class="swiper-pagination-current">1</span> / <span class="swiper-pagination-total">3</span></div>
                </div>
                <div class="hero-bottom-nav d-flex justify-content-between align-items-center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="slider-arrow d-flex w-20 justify-content-between">
                        <div class="swiper-button-prev ">
                            <img src="/assets/images/icon/arrow-left.png" alt="斡仑科技 - Left Arrow" />
                        </div>
                        <div class="swiper-button-next ">
                            <img src="/assets/images/icon/arrow-right.png" alt="斡仑科技 - Right Arrow" />
                        </div>
                        <!-- end swiper-button-next -->
                    </div>
                    <ul class="hero-social social-icon icon-sm m-0 xs-text-center text-right d-flex">
                        <!-- <li class="position-relative">
                            <a (click)="openIcon()">
                                <img src="/assets/images/icon/wechat_icon.png" alt="内蒙古斡仑科技 - 微信公众号" style="width: 20px; height: 20px;" />
                                <div *ngIf="!open1" class="position-absolute icon_Tooltip it_" [ngClass]="{'mgl': language === 'mgl', 'l_4': language === 'mgl'}">
                                    {{"微信公众号" | translate }}
                                </div>
                            </a>
                            <div class="popover" id="wi">
                                <img class="wechat" src="/assets/images/gz.jpg" alt="Onon官网 - 微信公众号">
                            </div>
                        </li> -->
                        <li class="position-relative">
                            <a href="http://doc.onon.cn" target="_blank" title="">
                                <img src="/assets/images/icon/doc_icon.png" alt="内蒙古斡仑科技 - Onon文库" style="width: 16px; height: 16px;" />
                                <div class="position-absolute icon_Tooltip it_doc" [ngClass]="{'mgl l_4': language === 'mgl'}">
                                    {{ "Onon文库" | translate }}
                                </div>
                            </a>
                        </li>
                        <li class="position-relative">
                            <a href="http://map.onon.cn" target="_blank" title="">
                                <img src="/assets/images/icon/map_icon.png" alt="内蒙古斡仑科技 - Onon地图" style="width: 16px; height: 16px;" />
                                <div class="position-absolute icon_Tooltip it_map" [ngClass]="{'mgl l_4': language === 'mgl'}">
                                    {{ "Onon地图" | translate }}
                                </div>
                            </a>
                        </li>
                        <li class="position-relative">
                            <a href="http://office.onon.cn" target="_blank" title="">
                                <img src="/assets/images/icon/office_icon.png" alt="内蒙古斡仑科技 - Onon办公套装" style="width: 16px; height: 16px;" />
                                <div class="position-absolute icon_Tooltip it_map" [ngClass]="{'mgl l_4': language === 'mgl'}">
                                    {{ "Onon办公套装" | translate }}
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!--Hero Social End-->
                </div>
            </div>
            <!--Hero Inner End-->
        </div>
        <!--Container End-->
    </div>
    <!--news Bottom End-->
</div>
<!-- slide  End  -->

<!-- Onon Start -->
<div class="aboutus" id="about">
    <!--About Main Container Start -->
    <div class="about-main-container">
        <div class="container">
            <div class="about-inner row">
                <!--About Left Start -->
                <div class="about-left col-md-6">
                    <div class="ab-left-img">
                        <img loading="lazy" src="{{url}}image/home/Onon.png" alt="内蒙古斡仑科技有限公司 - About Image" />
                    </div>
                </div>
                <!--About Left End -->
                <!--About Right Start -->
                <div class="about-right col-md-6" [ngClass]="{'padding-20-15': language === 'cy' || language === 'en'}">
                    <div>
                        <div class="about-right-inner" [ngClass]="{'height-auto': language !== 'mgl'}">
                            <div class="about-title">
                                <h2 class="title font-gq pinyin" [ngClass]="{'mgl h_70': language === 'mgl'}">
                                    {{"关于斡仑" | translate}}
                                </h2>
                            </div>
                            <div class="about-description" [ngClass]="language === 'mgl'?'width-360':' height-auto'">
                                <p class="pinyin" [ngClass]="{'mgl height-290 width-560': language === 'mgl','lh_32': language === 'cy'}">
                                    {{'内蒙古斡仑科技有限公司成立于2017年9月，公司主要经营人工智能领域内的软件、硬件和相关产品的研发、生产、咨询、服务，语言翻译、语言培训、数据资源加工、数据资源标注、语言技术服务、地理信息技术服务、遥感技术（遥感数据挖掘、遥感监测）服务、大数据展示、数据可视化服务等，是一个全新的高新技术软件企业。' | translate}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--About Right End -->
            </div>
        </div>
        <!-- Container End -->
    </div>
    <!--About Main Container End -->
</div>
<!-- Onon End -->

<!--product service  Start-->
<div *ngIf="false" class="service" id="service">
    <!-- <div class="container">
        <div class="about_onon row row-cols-1 row-cols-sm-2">
            <a href="/cn/qualification" class="col">
                <div class="ao_img">
                    <img loading="lazy" src="{{url+ 'image/qualification/qualification_01.jpg'}}" alt="">
                </div>
                <div class="ao_title">
                    资质荣誉
                </div>
            </a>
            <div class="col">
                <div class="ao_img">
                    <img loading="lazy" src="{{url+ 'image/home/onon01.jpg'}}" alt="">
                </div>
                <div class="ao_title">
                    团队建设
                </div>
            </div>
        </div>
    </div> -->


    <div class="d-flex justify-content-center align-items-center">
        <h2 class="public_title" [ngClass]="{'mgl h_97': language === 'mgl'}">
            {{"技术服务"| translate}}
        </h2>
    </div>
    <!--Slider Start-->
    <div class="container">
        <ngx-glide [gap]="10" [arrowLeftLabel]="left" [arrowRightLabel]="right" [perView]="1" [showBullets]="true" [showArrows]="false" type="slider" [hoverpause]="false">
            <div class="glideDiv" *ngFor="let pitem of productSliderList;let i = index">
                <div class="glideText" [ngClass]="language === 'mgl'?'d-flex':'glideText_center'">
                    <span>0{{ i + 1 }}</span>
                    <h6 class="font-gq" [ngClass]="{'mgl glideText_ttb_h6': language=== 'mgl'}">{{ pitem.name | translate }}</h6>
                    <p [ngClass]="{'mgl glideText_ttb_p': language=== 'mgl'}">{{ pitem.contText | translate }}</p>
                </div>
                <div class="glideImg">
                    <img loading="lazy" [src]="url + pitem.imgUrl" alt="内蒙古斡仑科技有限公司 - 技术服务" />
                </div>
            </div>
        </ngx-glide>
    </div>
    <!--Slider End-->
</div>
<!--product service  End -->

<!-- product start -->
<div class="" style="padding-top: 80px;" id="product">
    <!-- [ngClass]="{'height-320': language === 'mgl', 'height-266': language !== 'mgl'}" -->
    <div class="container d-flex justify-content-center">
        <h2 class="public_title text_black" [ngClass]="{'mgl h_120': language === 'mgl'}">
            {{"产品中心" | translate }}
        </h2>
    </div>
</div>
<!--Blog Start-->
<div class="container" style="padding-bottom: 80px;">
    <div class="category_title" [ngClass]="{'mgl ct_mn': language === 'mgl'}">
        {{"网络服务" | translate }}
    </div>
    <div class="row" [ngClass]="language=== 'mgl'? 'justify-content-start': 'justify-content-around'" style="margin-bottom: 30px;">

        <a href="{{iitem.component? '/'+language+iitem.linkUrl : iitem.linkUrl}}" target="_blank" class="margin-5 padding-5 shadow-hover" [ngClass]="{'width-356': language !== 'mgl','width-170':language === 'mgl' ,'mh_145': (i == 0 || i== 1 ) && language === 'cn'}"
            *ngFor="let iitem of internetService; let i = index">
            <div class="comments-box position-relative h-100" [ngClass]="{'d-flex': language === 'mgl'}">
                <!-- xcx-icon start -->
                <div *ngIf="iitem.QR " class="position-absolute {{iitem.QRClass}}">
                </div>
                <!-- xcx-icon End -->
                <div [ngClass]="{'d-flex': language !== 'mgl'}">
                    <div class="comments-avatar">
                        <img loading="lazy" src="{{ iitem.imgUrl }}" alt="{{iitem.name}}">
                    </div>
                    <div class="avatar-name d-flex" [ngClass]="language === 'mgl'?'w_75':'flex-column'">
                        <h4 class="margin-0-20" [ngClass]="{'mgl margin-0': language === 'mgl'}">{{ iitem.name | translate}}</h4>
                        <span *ngIf="language !== 'en'" [ngClass]="{'mgl': language === 'mgl'}">{{ iitem.enName }}</span>
                    </div>
                </div>
                <div class="comments-text" [ngClass]="{mgl: language === 'mgl'}">
                    <p [ngClass]="language === 'mgl'?'margin-0-10 height-175 maxH-300':'lr_p'">
                        {{ iitem.contText | translate}}
                    </p>
                </div>
            </div>
        </a>

        <!-- <a href="{{product.component? '/'+language+product.linkUrl : product.linkUrl}}" target="_blank" class="margin-5 padding-5 shadow-hover" [ngClass]="{'width-356': language !== 'mgl','width-170':language === 'mgl' ,'mh_145': (i == 0 || i== 1 ) && language === 'cn'}"
            *ngFor="let product of ProductList; let i = index">
            <div class="comments-box position-relative h-100" [ngClass]="{'d-flex': language === 'mgl'}">
                xcx-icon start
                <div *ngIf="product.QR " class="position-absolute {{product.QRClass}}">
                </div>
                xcx-icon End
                <div [ngClass]="{'d-flex': language !== 'mgl'}">
                    <div class="comments-avatar">
                        <img loading="lazy" src="{{ product.imgUrl }}" alt="{{product.name}}">
                    </div>
                    <div class="avatar-name d-flex" [ngClass]="language === 'mgl'?'w_75':'flex-column'">
                        <h4 class="margin-0-20" [ngClass]="{'mgl margin-0': language === 'mgl'}">{{ product.name | translate}}</h4>
                        <span *ngIf="language !== 'en'" [ngClass]="{'mgl': language === 'mgl'}">{{ product.enName }}</span>
                    </div>
                </div>
                <div class="comments-text" [ngClass]="{mgl: language === 'mgl'}">
                    <p [ngClass]="language === 'mgl'?'margin-0-10 height-175 maxH-300':'lr_p'">
                        {{ product.contText | translate}}
                    </p>
                </div>
            </div>
        </a>
        <a class="margin-5 padding-5 not-allowed" [ngClass]="language === 'mgl'?'width-170':'width-356'" *ngFor="let Pitem of productList">
            <div class="comments-box position-relative h-100" [ngClass]="{'d-flex': language === 'mgl'}">
                <div [ngClass]="{'d-flex': language !== 'mgl'}">
                    <div class="comments-avatar">
                        <img loading="lazy" src="{{ Pitem.imgUrl }}" alt="斡仑-产品中心">
                    </div>
                    <div class="avatar-name d-flex" [ngClass]="language === 'mgl'?'w_75':'flex-column'">
                        <h4 class="margin-0-20 c_6c6c6c" [ngClass]="{'mgl margin-0': language === 'mgl'}">{{ Pitem.name | translate}}</h4>
                        <span *ngIf="language !== 'en'" [ngClass]="{'mgl': language === 'mgl'}">{{ Pitem.enName }}</span>
                    </div>
                </div>
                <div class="comments-text" [ngClass]="{mgl: language === 'mgl'}">
                    <p [ngClass]="language === 'mgl'?'margin-0-10 height-175':'lr_p'">
                        {{ Pitem.contText | translate}}
                    </p>
                </div>
            </div>
        </a> -->
    </div>
    <div class="category_title" [ngClass]="{'mgl ct_mn': language === 'mgl'}">
        {{"办公软件" | translate }}
    </div>
    <div class="row" [ngClass]="language=== 'mgl'? 'justify-content-start': 'justify-content-around'" style="margin-bottom: 30px;">

        <a href="{{oitem.component? '/'+language+oitem.linkUrl : oitem.linkUrl}}" target="_blank" class="margin-5 padding-5 shadow-hover" [ngClass]="{'width-356': language !== 'mgl','width-170':language === 'mgl' ,'mh_145': (i == 0 || i== 1 ) && language === 'cn'}"
            *ngFor="let oitem of office; let i = index">
            <div class="comments-box position-relative h-100" [ngClass]="{'d-flex': language === 'mgl'}">
                <!-- xcx-icon start -->
                <div *ngIf="oitem.QR " class="position-absolute {{oitem.QRClass}}">
                </div>
                <!-- xcx-icon End -->
                <div [ngClass]="{'d-flex': language !== 'mgl'}">
                    <div class="comments-avatar">
                        <img loading="lazy" src="{{ oitem.imgUrl }}" alt="{{oitem.name}}">
                    </div>
                    <div class="avatar-name d-flex" [ngClass]="language === 'mgl'?'w_75':'flex-column'">
                        <h4 class="margin-0-20" [ngClass]="{'mgl margin-0': language === 'mgl'}">{{ oitem.name | translate}}</h4>
                        <span *ngIf="language !== 'en'" [ngClass]="{'mgl': language === 'mgl'}">{{ oitem.enName }}</span>
                    </div>
                </div>
                <div class="comments-text" [ngClass]="{mgl: language === 'mgl'}">
                    <p [ngClass]="language === 'mgl'?'margin-0-10 height-175 maxH-300':'lr_p'">
                        {{ oitem.contText | translate}}
                    </p>
                </div>
            </div>
        </a>
    </div>

    <div class="category_title" [ngClass]="{'mgl ct_mn': language === 'mgl'}">
        {{"语言产业" | translate }}
    </div>
    <div class="row justify-content-around" style="margin-bottom: 30px;">

        <a href="{{litem.component? '/'+language+litem.linkUrl : litem.linkUrl}}" target="_blank" class="margin-5 padding-5 shadow-hover" [ngClass]="{'width-356': language !== 'mgl','width-170':language === 'mgl' ,'mh_145': (i == 0 || i== 1 ) && language === 'cn'}"
            *ngFor="let litem of languageIndustry; let i = index">
            <div class="comments-box position-relative h-100" [ngClass]="{'d-flex': language === 'mgl'}">
                <!-- xcx-icon start -->
                <div *ngIf="litem.QR" class="position-absolute {{litem.QRClass}}">
                </div>
                <!-- xcx-icon End -->
                <div [ngClass]="{'d-flex': language !== 'mgl'}">
                    <div class="comments-avatar">
                        <img loading="lazy" src="{{ litem.imgUrl }}" alt="{{litem.name}}">
                    </div>
                    <div class="avatar-name d-flex" [ngClass]="language === 'mgl'?'w_75':'flex-column'">
                        <h4 class="margin-0-20" [ngClass]="{'mgl margin-0': language === 'mgl'}">{{ litem.name | translate}}</h4>
                        <span *ngIf="language !== 'en'" [ngClass]="{'mgl': language === 'mgl'}">{{ litem.enName }}</span>
                    </div>
                </div>
                <div class="comments-text" [ngClass]="{mgl: language === 'mgl'}">
                    <p [ngClass]="language === 'mgl'?'margin-0-10 height-175 maxH-300':'lr_p'">
                        {{ litem.contText | translate}}
                    </p>
                </div>
            </div>
        </a>
        <a class="margin-5 padding-5 not-allowed" [ngClass]="language === 'mgl'?'width-170':'width-356'" *ngFor="let Pitem of productList">
            <div class="comments-box position-relative h-100" [ngClass]="{'d-flex': language === 'mgl'}">
                <div [ngClass]="{'d-flex': language !== 'mgl'}">
                    <div class="comments-avatar">
                        <img loading="lazy" src="{{ Pitem.imgUrl }}" alt="斡仑-产品中心">
                    </div>
                    <div class="avatar-name d-flex" [ngClass]="language === 'mgl'?'w_75':'flex-column'">
                        <h4 class="margin-0-20 c_6c6c6c" [ngClass]="{'mgl margin-0': language === 'mgl'}">{{ Pitem.name | translate}}</h4>
                        <span *ngIf="language !== 'en'" [ngClass]="{'mgl': language === 'mgl'}">{{ Pitem.enName }}</span>
                    </div>
                </div>
                <div class="comments-text" [ngClass]="{mgl: language === 'mgl'}">
                    <p [ngClass]="language === 'mgl'?'margin-0-10 height-175':'lr_p'">
                        {{ Pitem.contText | translate}}
                    </p>
                </div>
            </div>
        </a>
    </div>
</div>

<div class="" style="" id="service">
    <!-- [ngClass]="{'height-320': language === 'mgl', 'height-266': language !== 'mgl'}" -->
    <div class="container d-flex justify-content-center">
        <h2 class="public_title text_black" [ngClass]="{'mgl h_120': language === 'mgl'}">
            {{"技术服务" | translate }}
        </h2>
    </div>
</div>

<div class="container" style="padding-bottom: 80px;">
    <div class="row justify-content-around" style="margin-bottom: 30px;">

        <a href="{{litem.component? '/'+language+litem.linkUrl : litem.linkUrl}}" target="_blank" class="margin-5 padding-5 shadow-hover" [ngClass]="{'width-356': language !== 'mgl','width-170':language === 'mgl' ,'mh_145': (i == 0 || i== 1 ) && language === 'cn'}"
            *ngFor="let litem of serviceList; let i = index">
            <div class="comments-box position-relative h-100" [ngClass]="{'d-flex': language === 'mgl'}">
                <!-- xcx-icon start -->
                <div *ngIf="litem.QR" class="position-absolute {{litem.QRClass}}">
                </div>
                <!-- xcx-icon End -->
                <div [ngClass]="{'d-flex': language !== 'mgl'}">
                    <div class="comments-avatar">
                        <img loading="lazy" src="{{ litem.imgUrl }}" alt="{{litem.name}}">
                    </div>
                    <div class="avatar-name d-flex" [ngClass]="language === 'mgl'?'w_75':'flex-column'">
                        <h4 class="margin-0-20" [ngClass]="{'mgl margin-0': language === 'mgl'}">{{ litem.name | translate}}</h4>
                        <span *ngIf="language !== 'en'" [ngClass]="{'mgl': language === 'mgl'}">{{ litem.enName }}</span>
                    </div>
                </div>
                <div class="comments-text" [ngClass]="{mgl: language === 'mgl'}">
                    <p [ngClass]="language === 'mgl'?'margin-0-10 height-175 maxH-300':'lr_p'">
                        {{ litem.contText | translate}}
                    </p>
                </div>
            </div>
        </a>
    </div>
</div>




<!-- wechat applet start-->
<div class="container" style="padding-bottom: 80px;" id="applet">
    <div class="d-flex justify-content-center align-items-center">
        <h2 class="public_title text_black" [ngClass]="{'mgl h_70': language === 'mgl'}">
            {{"微信小程序" | translate}}
        </h2>
    </div>
    <div class="wechat_applet row">
        <div class="col" *ngFor="let item of appletList">
            <div class="applet_item d-flex flex-column">
                <!-- <div class="applet_logo"><img src="/assets/images/product/ononDoc.png" alt=""></div> -->
                <div class="applet_text" [ngClass]="{'mgl height_80': language === 'mgl'}">
                    <h4 [ngClass]="{'text-center': language !== 'mgl'}">{{ item.name | translate}}</h4>
                    <div class="applet_text_cont" [ngClass]="{'text-center': language === 'en'}">{{ item.contText | translate}}</div>
                </div>
                <div class="applet_QR_code"> <img loading="lazy" src="{{url}}{{item.QRcodeUrl}}" alt="斡仑科技-微信小程序"></div>
            </div>
        </div>
    </div>
    <div class="tips d-flex justify-content-center align-items-center" [ngClass]="{'mgl height_60': language === 'mgl'}">{{"微信『扫一扫』即可体验" | translate }}</div>
</div>
<!-- wechat aoolet end -->
<div class="video_glide">
    <ngx-glide [gap]="0" [arrowLeftLabel]="left" [arrowRightLabel]="right" [perView]="1" [showBullets]="true" [showArrows]="false" type="slider" [hoverpause]="false">
        <!--Video Area Start-->
        <div class="video">
            <div class="overlay"></div>
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5 col-lg-5 ">
                        <!--Video Left Detail Start-->
                        <div class="video-left-detail " [ngClass]="{'d-flex': language === 'mgl'}">
                            <h1 class="title text-white" [ngClass]="{'mgl font-gq': language === 'mgl','padding-0': language === 'cy', 'fz_32': language === 'cy'}">
                                {{"Onon地图" | translate}}
                            </h1>
                            <p class="vdo-text cn-text mb_0" [ngClass]="{'mgl-text mgl font-oss video_t': language === 'mgl', 'cn-text': language !== 'mgl'}">
                                {{"Onon地图是以“一带一路’沿线多个国家和地区的蒙古语地名及其多媒体信息的开放式标注、展示、挖掘为主的在线地图，具有网站及微信小程序两种形式。" | translate }}
                            </p>
                            <p class="vdo-text cn-text" [ngClass]="{'mgl-text mgl font-oss video_t': language === 'mgl', 'cn-text': language !== 'mgl'}">
                                {{"搜集、标注超大规模蒙汉英及西里尔蒙古文对齐的蒙古语地名基础上，在蒙古文地理信息系统、蒙古文智能搜索、跨语言搜索、语音录入、机器翻译、新旧蒙古文转换、OCR识别等多个领域的技术突破及综合应用，成为首款多文种在线地图。" | translate}}
                            </p>
                        </div>
                        <!--Video Left Detail End-->
                    </div>
                    <div class="col-md-2 col-lg-2 text-center icon-effect icon-effect-1a">
                        <!--Video Center Start-->
                        <a class="video-icon icon d-flex justify-content-center align-items-center" href="https://onon-guanwang.oss-cn-beijing.aliyuncs.com/video/onon_map.mp4" data-lity="">
                            <div class="d-flex align-items-center justify-content-center ">
                                <i class="fa fa-play"></i>
                            </div>
                        </a>
                        <!--Video Center End-->
                    </div>
                    <div class="col-md-5 col-lg-5">
                        <!--Video Right Start-->
                        <div class="video-img">
                            <img loading="lazy" src="{{url}}image/product/vimg_map2.png" alt="Onon官网-Onon地图-视频" />
                        </div>
                        <!--Video Right End-->
                    </div>
                </div>
            </div>
            <!--container End-->
        </div>
        <!--Video Area End-->
        <!--Video Area Start-->
        <div class="video">
            <div class="overlay"></div>
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5 col-lg-5 ">
                        <!--Video Left Detail Start-->
                        <div class="video-left-detail " [ngClass]="{'d-flex': language === 'mgl'}">
                            <h1 class="title text-white" [ngClass]="{'mgl font-gq': language === 'mgl', 'padding-0': language === 'cy', 'fz_32': language === 'cy'}">
                                {{"光辉之路" | translate}}
                            </h1>
                            <p class="vdo-text cn-text" [ngClass]="{'mgl mgl-text font-oss': language === 'mgl','cn-text': language !== 'mgl'}">
                                {{"《光辉之路》是由青年创业者包乐森提倡出品的一首创业励志歌曲，7家不同领域的企业联合出品，3家企业友情赞助参与。 该歌曲献给每一位历经风雨的创业者，更是唱给所有为梦想拼搏的有志之士，鼓励更多优秀年轻人朝着梦想的方向砥砺前行！" | translate }}
                            </p>
                        </div>
                        <!--Video Left Detail End-->
                    </div>
                    <div class="col-md-2 col-lg-2 text-center icon-effect icon-effect-1a">
                        <!--Video Center Start-->
                        <a class="video-icon icon d-flex justify-content-center align-items-center" href="https://onon-guanwang.oss-cn-beijing.aliyuncs.com/video/vogj.mp4" data-lity="">
                            <div class="d-flex align-items-center justify-content-center ">
                                <i class="fa fa-play"></i>
                            </div>
                        </a>
                        <!--Video Center End-->
                    </div>
                    <div class="col-md-5 col-lg-5">
                        <!--Video Right Start-->
                        <div class="video-img">
                            <img loading="lazy" src="{{url}}image/product/vogj.png" alt="Onon官网-视频" />
                        </div>
                        <!--Video Right End-->
                    </div>
                </div>
            </div>
            <!--container End-->
        </div>
        <!--Video Area End-->
    </ngx-glide>
</div>
<!-- product start -->
<div class="box_spacing" id="case">
    <div class="container">
        <div class="d-flex justify-content-center align-items-center">
            <h2 class="public_title text_black" [ngClass]="{'mgl h_105': language === 'mgl'}">
                {{"成功案例" | translate}}
            </h2>
        </div>
        <div class="row">
            <!--Portfolio  Start-->
            <div class="portfoliodiv">
                <div class="col-25 portfolio-card" *ngFor="let success of successList">
                    <div class="portfolio-img">
                        <a href="{{success.linkUrl}}" target="_blank">
                            <img src="{{url}}{{success.imgUrl}}" alt="内蒙古斡仑科技有限公司-成功案例-{{ success.title | translate }}" />
                            <div class="portfolio-overlay">
                                <div [ngClass]="{'mgl': language === 'mgl'}">{{ success.title | translate }}</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!--Portfolio  End-->
        </div>
    </div>
    <!--Container End-->
</div>
<!-- product end -->
<!--Reruit  Start -->
<div class="box_spacing" id="recruit">
    <div class="container">
        <div class="d-flex justify-content-center align-items-center">
            <h2 class="public_title text_black" [ngClass]="{'mgl h_97': language === 'mgl'}">
                {{"招贤纳士" | translate }}
            </h2>
        </div>
        <!--Testimonial Slider  Start-->
        <div class="testimonial-slider">
            <div class="testimonial-inner">
                <div class="row">
                    <div class="testimonial-left col-md-6">
                        <div class="test-left-img">
                            <img loading="lazy" src="{{url}}image/home/jrwm.jpg" alt="内蒙古斡仑科技有限公司-招贤纳士" />
                        </div>
                    </div>
                    <div class="testimonial-right col-md-6">
                        <div class="has-animation">
                            <div class="testimonial-right-inner" [ngClass]="language === 'mgl'?'width-345 w_430':'height-auto'">
                                <div class="testimonial-description" [ngClass]="{mgl: language === 'mgl'}">
                                    <h3>{{"优秀人才培养计划、五险一金、带薪年假 、定期体检、节日福利..."| translate}}</h3>
                                    <div class="text-center">
                                        <a href="/cn/recruit " class="load-btn-2" [ngClass]="{'load-btn-2-mgl': language === 'mgl'}">
                                            <span>{{"了解更多"| translate}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Reruit  End -->
<!--News Area  Start -->
<div class="box_spacing">
    <div id="news">
        <!-- [ngClass]="{'height-340': language === 'mgl', 'height-266': language !== 'mgl'}" -->
        <div class="container d-flex justify-content-center position-relative" id="blog-bg">
            <h2 class="public_title text_black" [ngClass]="{'mgl h_93': language === 'mgl'}">
                {{ "媒体报道" | translate}}
            </h2>
            <span class="position-absolute font-oss move_news" [ngClass]="{'width_60': language === 'mgl', 'bottom_-15': language === 'cy'}">
            <a href="/{{language}}/news" [ngClass]="{'mgl': language === 'mgl'}">{{"更多"| translate}}</a><span>>></span>
            </span>
        </div>
    </div>
    <div class="container" style="padding-top: 10px;">
        <div class="row ">
            <!--News item Start-->
            <a class="blog-grid col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-between flex-column" href="{{item.textLink == '' ?homeHref + item.id:item.textLink }}" target="{{item.textLink==''?'':'_blank'}}" *ngFor="let item of newsList">
                <div class="blog-img">
                    <img loading="lazy" src="https://onon-guanwang.oss-cn-beijing.aliyuncs.com/{{item.images}}" alt="内蒙古斡仑科技有限公司-媒体报道" class="img-fluid" />
                    <span *ngIf="language == 'cn'" class="blog-tag font-oss" [ngClass]="language === 'mgl'?'mgl mgl-blog-tag':'cn-blog-tag'">
                        {{ item.textUnitName }}
                    </span>
                    <span *ngIf="language == 'mgl'" class="blog-tag font-oss" [ngClass]="language === 'mgl'?'mgl mgl-blog-tag':'cn-blog-tag'">
                        {{ item.textMUnitName }}
                    </span>
                </div>
                <div class="blog-inner d-flex justify-content-center flex-column" [ngClass]="{'h_185': language === 'mgl'}">
                    <div *ngIf="language == 'cn'" class="blog-t-big font-oss" [ngClass]="language === 'mgl'?'mgl mgl-blog-t-big':'cn-blog-t-big'">
                        {{ item.textChineseTitle }}
                    </div>
                    <div *ngIf="language == 'mgl'" class="blog-t-big font-oss" [ngClass]="language === 'mgl'?'mgl mgl-blog-t-big':'cn-blog-t-big'">
                        {{ item.title}}
                    </div>
                    <div class="blog-text d-flex justify-content-end align-items-center">
                        <div class="blog-text-right t-small">
                            {{item.dateStart.slice(0,10)}}
                        </div>
                    </div>
                </div>
            </a>
            <!--News item End-->
        </div>
    </div>
</div>
<!-- News Area End -->
<!-- cooperation mian Start -->
<div class="box_spacing" id="cooperation">
    <!-- padding-top-80 -->
    <div class="container">
        <div class="d-flex justify-content-center align-items-center">
            <h2 class="public_title text_black" [ngClass]="{'mgl H_100': language === 'mgl'}">
                {{"合作伙伴"| translate}}
            </h2>
        </div>
        <div style="padding-top: 10px;">
            <ngx-glide [perView]="perView" [autoplay]="3000" [showBullets]="false" [showArrows]="false" type="carousel" [hoverpause]="false">
                <div class="users-img col d-flex flex-column justify-content-center align-items-center" *ngFor="let item of userData">
                    <img loading="lazy" [src]="url + item.imgUrl" [ngClass]="{'h_100': IE !== '-1'}" alt="内蒙古斡仑科技有限公司-合作伙伴" />
                    <!-- <h4 [ngClass]="{mgl: language === 'mgl'}">{{ item.name |translate}}</h4> -->
                </div>
            </ngx-glide>
        </div>
    </div>
    <!--Container End-->
</div>
<!-- cooperation mian End -->