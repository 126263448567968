import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public router: Router, public translateService: TranslateService) {}
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    this.translateService.addLangs(['mg', 'cn', 'en', 'cy']);
    this.router.events
      .subscribe((event) => {
        $(window).scrollTop(0);
    });
    this.IEVersion();
  }
  IEVersion() {
    const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
    const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1; // 判断是否IE<11浏览器
    const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
    const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
    if (isIE) {
      const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
      reIE.test(userAgent);
      const fIEVersion = parseFloat(RegExp['$1']);
      if (fIEVersion === 7) {
        this.browser(7);
        return 7;
      } else if ( fIEVersion === 8) {
        this.browser(8);
        return 8;
      } else if (fIEVersion === 9) {
        this.browser(9);
        return 9;
      } else if (fIEVersion === 10) {
        this.browser(10);
        return 10;
      } else {
        this.browser(6);
        return 6; // IE版本<=7
      }
    } else if (isEdge) {
      this.browser('edge');
      return 'edge'; // edge
    } else if (isIE11) {
      this.browser(11);
      return 11; // IE11
    } else {
      this.browser(-1);
      return -1; // 不是ie浏览器
    }
  }
  browser(userAgent) {
    localStorage.browser = userAgent;
  }
}
