import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  name = 'news';
  language;
  newsList;
  newsContent;
  constructor(private route: ActivatedRoute, private el: ElementRef, private http: HttpClient, private api: ApiService) { }

  ngOnInit(): void {
    this.language = localStorage.language;
    const id = this.route.snapshot.paramMap.get('id'); 
    this.GetNewsDetails(id)
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    if ( this.language === 'mgl') {
    this.el.nativeElement.querySelector('#mglNews')
      .addEventListener('mousewheel',
        (e) => {
          // tslint:disable-next-line: deprecation
          e = e || window.event;
          const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
          this.el.nativeElement.querySelector('#mglNews').scrollLeft -= (delta * 40);
          e.preventDefault();
        }
      );
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }
  GetNewsDetails(id) {
    this.api.GetNews().subscribe(data => {
      this.newsList = data;
      for(let i in this.newsList){
        if(this.newsList[i].id == id) {
          this.newsContent = this.newsList[i];
        }
      }
    })
  }
}