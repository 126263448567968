import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit {
  language;
  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.router.url.slice(1, 3) === 'mg') {
      this.language = 'mgl';
    } else {
      this.language = this.router.url.slice(1, 3);
    }
  }
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }
}
