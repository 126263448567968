import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-corpus',
  templateUrl: './corpus.component.html',
  styleUrls: ['./corpus.component.scss']
})
export class CorpusComponent implements OnInit {
  url = this.api.aliApi;
  language;
  corpusList = [
    {
      image: 'image/corpus/dy001.jpg',
      title: '传统蒙古文新闻事件',
      content: '1万条'
    },
    {
      image: 'image/corpus/dy002.jpg',
      title: '传统蒙古文公文',
      content: '4万篇'
    },
    {
      image: 'image/corpus/citem003.jpg',
      title: '传统蒙古文习语',
      content: '2万条'
    },
    {
      image: 'image/corpus/dy004.jpg',
      title: '传统蒙古文日常短语',
      content: '8万条'
    },
    {
      image: 'image/corpus/dy005.jpg',
      title: '传统蒙古文社交评论',
      content: '0.6万条'
    },
    {
      image: 'image/corpus/dy006.jpg',
      title: '西里尔蒙古文综合语料',
      content: ' 60,321条'
    },
    {
      image: 'image/corpus/dy007.jpg',
      title: '回鹘式蒙古文文本',
      content: '56,920条'
    },
    {
      image: 'image/corpus/dy008.jpg',
      title: '拉丁转写文本',
      content: '8,178条'
    },
    {
      image: 'image/corpus/dy009.jpg',
      title: '传统蒙古文预训练模型定制',
      content: 'COC1'
    },
    {
      image: 'image/corpus/dy010.jpg',
      title: '西里尔蒙古文预训练模型定制',
      content: 'COC2'
    },
  ];

  alignList = [
    {
      image: 'image/corpus/dq001.jpg',
      title: '传统蒙古文与中文对齐语料',
      content: '10万组'
    },
    {
      image: 'image/corpus/dq002.jpg',
      title: '传统蒙古文与西里尔文对齐语料',
      content: '7万组'
    },
    {
      image: 'image/corpus/dq003.jpg',
      title: '传统蒙古文与英文对齐语料',
      content: '8万组'
    },
    {
      image: 'image/corpus/dq004.jpg',
      title: '西里尔文与中文对齐文本语料',
      content: '8.3万组'
    },
    {
      image: 'image/corpus/dq005.jpg',
      title: '传统蒙古文与回鹘式蒙古文文本',
      content: '3万组'
    },
    {
      image: 'image/corpus/dq006.jpg',
      title: '传统蒙古文与拉丁文',
      content: '2万组'
    },
  ]
  dataSet = [
    {
      image: 'image/corpus/yysb001.jpg',
      title: ' 蒙古语通话语音',
      content: '200小时'
    },
    {
      image: 'image/corpus/yysb002.jpg',
      title: '蒙古语习语朗读',
      content: '97小时'
    },
    {
      image: 'image/corpus/yysb003.jpg',
      title: '蒙古语日常聊天',
      content: '441小时'
    },
    {
      image: 'image/corpus/yysb004.jpg',
      title: '蒙古语采访语音',
      content: '320小时'
    },
    {
      image: 'image/corpus/yysb005.jpg',
      title: '蒙中混合语音',
      content: '100小时'
    },
    {
      image: 'image/corpus/yysb006.jpg',
      title: '蒙古语地区方言语音',
      content: '462小时'
    },
    {
      image: 'image/corpus/yysb007.jpg',
      title: '蒙英混合语音',
      content: '232小时'
    },    
    {
      image: 'image/corpus/yysb008.jpg',
      title: '蒙古语会议语音',
      content: '142小时'
    },    
    {
      image: 'image/corpus/yysb009.jpg',
      title: '蒙古语客服语音',
      content: '98小时'
    },
    {
      image: 'image/corpus/yysb010.jpg',
      title: '蒙古语歌曲',
      content: '1,535小时'
    },
  ];
  yyhcList = [
    {
      image: 'image/corpus/yyhc001.jpg',
      title: '蒙古语成人女声',
      content: ''
    },
    {
      image: 'image/corpus/yyhc002.jpg',
      title: '蒙古语成人男声',
      content: ''
    },
    {
      image: 'image/corpus/yyhc003.jpg',
      title: '蒙古语童声',
      content: ''
    },
    {
      image: 'image/corpus/yyhc004.jpg',
      title: '蒙古语播音腔',
      content: ''
    },
    {
      image: 'image/corpus/yyhc005.jpg',
      title: '蒙古语客服腔',
      content: ''
    },
    {
      image: 'image/corpus/yyhc006.jpg',
      title: '蒙古语故事腔',
      content: ''
    },
  ];

  visualList = [
    {
      image: 'image/corpus/citem007.jpg',
      title: '蒙古文手写体数据',
      content: '100人手写体数据，对于每个人，书写的语料库均不同。'
    },
    {
      image: 'image/corpus/citem006.jpg',
      title: '蒙汉自然场景OCR数据',
      content: '222,289张，传统蒙古文与汉文自然场景OCR数据。'
    },
    {
      image: 'image/corpus/citem008.jpg',
      title: '蒙汉牌匾OCR数据',
      content: '71,535张，传统蒙古文与汉文牌匾OCR数据。'
    },
    {
      image: 'image/corpus/citem002.jpg',
      title: '多语言自然场景OCR数据',
      content: '105,941张，传统蒙古文、汉文、英文等语言自然场景OCR数据。'
    },
    {
      image: 'image/corpus/citem005.jpg',
      title: '蒙古文PPT OCR数据',
      content: '14,980张'
    },
    {
      image: 'image/corpus/citem001.jpg',
      title: '蒙古文试卷及作业采集数据',
      content: '17,561张，小学蒙古文试卷、小学蒙古文作业采集数据。'
    },
    {
      image: 'image/corpus/citem004.jpg',
      title: '蒙古文书籍采集数据',
      content: '114,538张'
    },
    {
      image: 'image/corpus/citem009.jpg',
      title: '蒙古文字幕视频',
      content: '432小时'
    },
    {
      image: 'image/corpus/citem010.jpg',
      title: '西里尔文字幕视频',
      content: '332小时'
    },
  ]
  constructor(private api: ApiService,private router: Router,) {
  }


  ngOnInit(): void {
    // url => setLanguage,localStorage.language
    if (this.router.url.slice(1, 3) === 'mg') {
      this.language = 'mgl';
    } else {
      this.language = this.router.url.slice(1, 3);
    }
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }
}
