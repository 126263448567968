import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { RecruitComponent } from './component/recruit/recruit.component';
import { NewsComponent } from './component/news/news.component';
import { DetailComponent } from './component/news/detail/detail.component';
import { IndexComponent } from './component/index/index.component';
import { QualificationComponent } from './component/qualification/qualification.component';
import { CmsComponent } from './component/cms/cms.component';
import { CorpusComponent } from './component/corpus/corpus.component';
import { LegalNoticesComponent } from './component/legal-notices/legal-notices.component';
import { TextualizationComponent } from './component/textualization/textualization.component';
import { MediaComponent } from './component/media/media.component';
import { JournalComponent } from './component/journal/journal.component';
import { TranslateComponent } from './component/translate/translate.component';


const routes: Routes = [
  {path: '', redirectTo: 'cn', pathMatch: 'full' },
  // {path: 'mgl', component: IndexComponent, children: [
  //   {path: '', redirectTo: 'home', pathMatch: 'full' },
  //   {path: 'home', component: HomeComponent},
  //   {path: 'recruit', component: RecruitComponent},
  //   {path: 'news', component: NewsComponent},
  //   {path: 'news/:id', component: DetailComponent},
  //   {path: 'qualification', component: QualificationComponent},
  //   {path: 'corpus', component: CorpusComponent},
  //   {path: 'cms', component: CmsComponent},
  //   {path: 'legalNotices', component: LegalNoticesComponent},
  //   {path: 'textualization', component: TextualizationComponent},
  //   {path: 'media', component: MediaComponent},
  //   {path: 'journal', component: JournalComponent},
  //   {path: 'translate', component: TranslateComponent}
  // ]},
  {path: 'cn', component: IndexComponent, children: [
    {path: '', redirectTo: 'home', pathMatch: 'full' },
    {path: 'home', component: HomeComponent},
    {path: 'recruit', component: RecruitComponent},
    {path: 'news', component: NewsComponent},
    {path: 'news/:id', component: DetailComponent},
    {path: 'qualification', component: QualificationComponent},
    {path: 'corpus', component: CorpusComponent},
    {path: 'cms', component: CmsComponent},
    {path: 'legalNotices', component: LegalNoticesComponent},
    {path: 'textualization', component: TextualizationComponent},
    {path: 'media', component: MediaComponent},
    {path: 'journal', component: JournalComponent},
    {path: 'translate', component: TranslateComponent}
  ]},
  {path: 'en', component: IndexComponent, children: [
    {path: '', redirectTo: 'home', pathMatch: 'full' },
    {path: 'home', component: HomeComponent},
    {path: 'recruit', component: RecruitComponent},
    {path: 'news', component: NewsComponent},
    {path: 'news/:id', component: DetailComponent},
    {path: 'qualification', component: QualificationComponent},
    {path: 'corpus', component: CorpusComponent},
    {path: 'cms', component: CmsComponent},
    {path: 'legalNotices', component: LegalNoticesComponent},
    {path: 'textualization', component: TextualizationComponent},
    {path: 'media', component: MediaComponent},
    {path: 'journal', component: JournalComponent},
    {path: 'translate', component: TranslateComponent}
  ]},
  // {path: 'cy', component: IndexComponent, children: [
  //   {path: '', redirectTo: 'home', pathMatch: 'full' },
  //   {path: 'home', component: HomeComponent},
  //   {path: 'recruit', component: RecruitComponent},
  //   {path: 'news', component: NewsComponent},
  //   {path: 'news/:id', component: DetailComponent},
  //   {path: 'qualification', component: QualificationComponent},
  //   {path: 'corpus', component: CorpusComponent},
  //   {path: 'cms', component: CmsComponent},
  //   {path: 'legalNotices', component: LegalNoticesComponent},
  //   {path: 'textualization', component: TextualizationComponent},
  //   {path: 'media', component: MediaComponent},
  //   {path: 'journal', component: JournalComponent},
  //   {path: 'translate', component: TranslateComponent}
  // ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
