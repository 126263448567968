<app-nav [name]="name"></app-nav>

<div class="cont_header_bg" [ngClass]="language === 'mgl'?'height-340':'height-266'">
    <div class="container d-flex justify-content-center position-relative" id="blog-bg">
        <h2 class="news_title" [ngClass]="{'mgl height-125':language === 'mgl','_active':unitType=='media'}" style="margin-right: 24px;" (click)="selectTabs('media')">
            {{ "媒体报道" | translate}}
        </h2>
        <h2 class="news_title" [ngClass]="{'mgl height-125':language === 'mgl','_active':unitType=='company'}" (click)="selectTabs('company')">
            {{ "公司报道" | translate}}
        </h2>
    </div>
</div>

<div id="Blog-page">
    <div class="container">
        <div class="row">
            <!--News item Start-->
            <a class="blog-grid col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-between flex-column" href="{{item.textLink == '' ?homeHref + item.id:item.textLink }}" target="{{item.textLink==''?'':'_blank'}}" *ngFor="let item of newsList;let l = last">
                <div class="blog-img">
                    <img loading="lazy" src="https://onon-guanwang.oss-cn-beijing.aliyuncs.com/{{item.images}}" alt="内蒙古斡仑科技有限公司-媒体报道图片" class="img-fluid" />
                    <span *ngIf="language == 'cn'" class="blog-tag font-oss" [ngClass]="language === 'mgl'?'mgl mgl-blog-tag':'cn-blog-tag'">
                        {{ item.textUnitName }}
                    </span>
                    <span *ngIf="language == 'mgl'" class="blog-tag font-oss" [ngClass]="language === 'mgl'?'mgl mgl-blog-tag':'cn-blog-tag'">
                        {{ item.textMUnitName }}
                    </span>
                </div>
                <div class="blog-inner d-flex justify-content-center flex-column" [ngClass]="{'h_185': language === 'mgl'}">
                    <div *ngIf="language == 'cn'" class="blog-t-big font-oss" [ngClass]="language === 'mgl'?'mgl mgl-blog-t-big':'cn-blog-t-big'">
                        {{ item.textChineseTitle }}
                    </div>
                    <div *ngIf="language == 'mgl'" class="blog-t-big font-oss" [ngClass]="language === 'mgl'?'mgl mgl-blog-t-big':'cn-blog-t-big'">
                        {{ item.title }}
                    </div>
                    <div class="blog-text d-flex justify-content-end align-items-center">
                        <div class="blog-text-right t-small">
                            {{item.dateStart.slice(0,10)}}
                        </div>
                    </div>
                </div>
                {{ l === true ? viewInit() : null}}
            </a>
            <!--News item End-->
        </div>
    </div>
</div>