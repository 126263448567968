import { Component, ElementRef, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  language;
  width = false;
  url = this.api.aliApi;
  date = new Date();
  wechatAppletList = this.api.GetWechatAppletList();
  list = [];
  constructor(private el: ElementRef,private api: ApiService) { }

  ngOnInit(): void {
    if (navigator.userAgent.indexOf('Safari') !== -1) {
      // console.log(this.checkChromeFor360());
      this.width = this.checkChromeFor360();
    }
  }
  
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }
  checkChromeFor360() {
    const uas = navigator.userAgent.split(' ');
    const result = false;
    // 排除ua自带标志的双核浏览器, 余下chrome,chromium及360浏览器
    if (uas[uas.length - 1].indexOf('Safari') === -1) {
        return result;
    }
    for (const key in navigator.plugins) {
        // np-mswmp.dll文件在chromium及chrome未查询到
        if (navigator.plugins[key].filename === 'np-mswmp.dll') {
            return !result;
        }
    }
    return result;
  }
  OpenPosition() {
    window.open('https://map.baidu.com/poi/%E9%93%B6%E9%83%BD%E5%A4%A7%E5%8E%A6B%E5%BA%A7/@12432295.273168432,4956389.391592342,19.18z?uid=be1ea5efa7834cbf14948e14&info_merge=1&isBizPoi=false&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl');
  }
}
