import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss']
})
export class JournalComponent implements OnInit {
  language;
  contentList = [
    {
      img: './assets/images/j-1.png',
      title: '用户投稿',
      content: '支持多种格式，用户随时随地方便投稿',
    },
    {
      img: './assets/images/j-2.png',
      title: '来稿筛查',
      content: '保护系统内容安全性，筛查来稿、过滤错敏内容',
    },
    {
      img: './assets/images/j-3.png',
      title: '多模审核',
      content: '按需调整审核轮次，适配多种审核规则，全程保留审核痕迹，实时云端保存',
    },
    {
      img: './assets/images/j-4.png',
      title: '自由排版',
      content: '无限制自由排版，云端实时保存排版结果',
    },
    {
      img: './assets/images/j-5.png',
      title: '双模校对',
      content: '支持线上线下双模式校对，打印校对更顺手，移动端校对更随心',
    },
    {
      img: './assets/images/j-6.png',
      title: '终稿发布',
      content: '系统尾端连接期刊网，终稿可直接发布至期刊检索阅览平台',
    },
    {
      img: './assets/images/j-7.png',
      title: '嵌入式设计',
      content: '将系统嵌入到Onon office中，为审校提供更专业的环境和工具',
    },
    {
      img: './assets/images/j-8.png',
      title: '全方位可控后端',
      content: '配置统一中控后台，对全流程产生的文件实时保存，进度可视，操作可控',
    }
  ]
  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.router.url.slice(1, 3) === 'mg') {
      this.language = 'mgl';
    } else {
      this.language = this.router.url.slice(1, 3);
    }
  }
  ngDoCheck() {
    if (this.language !== localStorage.language) {
      this.language = localStorage.language;
    }
  }

}
