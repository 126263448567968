<app-nav></app-nav>
<div class="cms_top">
    <div class="cms_top_title">
        <h2 class="public_title">
            {{"法律声明"| translate}}
        </h2>
    </div>
</div>
<div id="Blog-page" class="cms_container">
    <div class="container">
        <div class="d-flex justify-content-center">
            <p style="font-size: 16px; line-height: 24px;">我公司所有网站、所有产品（包括但不限于网站、软件、应用程序、电脑输入法、手机输入法、小程序等）及其他任何公开资料（包括但不限于说明文档、教程文档、教程视频、新闻材料、公众号推文、视频号视频等）中“方正编码、方正文档（6.0版、9.0版、飞翔、飞腾、书版、报版等）、社科院MIT编码、民委共享工程编码、蒙科立编码、德力海编码、奥云编码、赛音编码、Bainu编码、微软产品、Windows系统、Android系统、iOS系统、MacOS系统、Linux系统、统信UOS系统、麒麟系统、WPS文档、金山Office、永中Office、MS
                Office、LibreOffice、OpenOffice"等说明中的公司名称、商标名称、标志、商号、缩写，包括对应蒙古文名称（例如ᠮᠥᠩᠬᠡᠭᠠᠯ ᠺᠣᠳ᠋）、西里尔文名称（例如Мөнхгал код）、英文名称（例如MenkCode）及其他任何文字翻译名称，其商标权归持有者所有，我公司仅作为商标所有人冠名编码、产品的描述说明性使用！如果对以上商标或公司名称持有者带来不利影响，请及时联系我们并告知如何合理引用和描述！</p>
        </div>

    </div>
</div>