import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './component/nav/nav.component';
import { HomeComponent } from './component/home/home.component';
import { RecruitComponent } from './component/recruit/recruit.component';
import { NewsComponent } from './component/news/news.component';
import { DetailComponent } from './component/news/detail/detail.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { IndexComponent } from './component/index/index.component';
import { FooterComponent } from './component/footer/footer.component';
import { QualificationComponent } from './component/qualification/qualification.component';
import { CmsComponent } from './component/cms/cms.component';
// 这里配置
export function createTranslateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { NgxGlideModule } from 'ngx-glide';
import { CorpusComponent } from './component/corpus/corpus.component';
import { LegalNoticesComponent } from './component/legal-notices/legal-notices.component';
import { TextualizationComponent } from './component/textualization/textualization.component';
import { TranslateComponent } from './component/translate/translate.component';
import { MediaComponent } from './component/media/media.component';
import { JournalComponent } from './component/journal/journal.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    RecruitComponent,
    NewsComponent,
    DetailComponent,
    IndexComponent,
    FooterComponent,
    QualificationComponent,
    CmsComponent,
    CorpusComponent,
    LegalNoticesComponent,
    TextualizationComponent,
    TranslateComponent,
    MediaComponent,
    JournalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateHttpLoader),
        deps: [HttpClient]
      }
    }),
    NgxGlideModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
