<app-nav></app-nav>

<div style="background-color: #fff;">
    <div class="corpus_top">
        <div class="ct_container d-flex container">
            <div class="ctc_content">
                <div class="ctcc_title" style="color: inherit;">
                    {{"翻译服务"| translate}}
                </div>
                <div class="ctcc_title_en" *ngIf="language !== 'en'">
                    Translation service
                </div>
                <div class="ctcc_title_sub" style="color: inherit;">
                    {{"translateIntroduction"| translate}}
                </div>
            </div>
            <div class="ctc_img">
                <img loading="lazy" src="./assets/images/fy-0.png" alt="">
            </div>
        </div>
    </div>

    <div class="ctbg">
        <div class="container fy-mt ">
            <div class="d-flex fy-c-1">
                <div class="fy-w-50">
                    <img src="./assets/images/fy-2.png" alt="" srcset="">
                </div>
                <div class="fy-c-1-c">
                    <h2>{{"辅助翻译系统" | translate}}</h2>
                    <p>{{"可系统地进行机器翻译工作，对翻译员进行任务派发或新建快速翻译。" | translate}}</p>
                    <p>{{"可查看翻译人员已办事项、待办事项、翻译进度等信息。" | translate}}</p>
                </div>
            </div>
            <div class="t-c-2">
                <h2 class="text-center">
                    {{"辅助翻译系统工作流程" | translate}}
                </h2>
                <div class="flex-center">
                    <img src="./assets/images/fy-1.png" alt="" class="i-w t-ib">
                </div>
            </div>
        </div>
    </div>

    <div class="container fy-mt">
        <div class="text-center">

            <h2>
                {{"运营团队" |translate}}
            </h2>
            <!-- <p>专业成熟团队，网站群翻译全流程业务服务</p> -->
            <p>{{"精锐翻译团队，对稿件进行精准把控，严格遵循国家、自治区所规定的标准化名词术语规范，蒙古语言文字正字法标准。" |translate}}</p>
        </div>
        <div class="flex-center">
            <img src="./assets/images/fy-3.png" alt="" class="i-w">
        </div>
    </div>

    <br><br>
    <br>


    <div class="ctbg">
        <div class="container fy-mt">
            <div class="text-center">
                <h2>
                    {{"其他服务" |translate}}
                </h2>
                <p>{{"对网站进行整体视觉优化，每次提供2-3个版本效果图供选择；可根据单位特色进行特色栏目策划建设；特色活动策划丰富宣发方式；可依据需求进行无障碍服务和适老化服务。" |translate}}</p>

            </div>

            <div class="flex-center">
                <img src="./assets/images/fy-4.png" alt="" class="i-w t-ib">
            </div>

        </div>
    </div>


    <div class="container fy-mt">
        <h2 class="text-center">
            {{"案例" |translate}}
        </h2>

        <div class="row row-cols-2 row-cols-lg-3">
            <div class="col">
                <img src="./assets/images/fy-al-1.png" alt="" class="tl-i tl-is">

            </div>
            <div class="col">
                <img src="./assets/images/fy-al-2.png" alt="" class="tl-i tl-is">

            </div>
            <div class="col">
                <img src="./assets/images/fy-al-3.png" alt="" class="tl-i tl-is">

            </div>
            <div class="col">
                <img src="./assets/images/fy-al-4.png" alt="" class="tl-i tl-is">

            </div>
            <div class="col">
                <img src="./assets/images/fy-al-5.png" alt="" class="tl-i tl-is">

            </div>
            <div class="col">
                <img src="./assets/images/fy-al-6.png" alt="" class="tl-i tl-is">

            </div>

        </div>
        <div class="row row-cols-1 row-cols-md-2">
            <div class="col">
                <img src="./assets/images/fy-al-7.png" alt="" class="tl-i">
            </div>
            <div class="col">
                <img src="./assets/images/fy-al-8.png" alt="" class="tl-i">

            </div>
            <div class="col">
                <img src="./assets/images/fy-al-9.png" alt="" class="tl-i">

            </div>
            <div class="col">
                <img src="./assets/images/fy-al-10.png" alt="" class="tl-i">

            </div>
            <div class="col">
                <img src="./assets/images/fy-al-11.png" alt="" class="tl-i">

            </div>
            <div class="col">
                <img src="./assets/images/fy-al-12.png" alt="" class="tl-i">

            </div>
        </div>
    </div>

    <br>
    <br>
    <br>
</div>